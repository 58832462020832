import React from "react";
class Loading extends React.Component {
    render() {
        return (
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            ></div>
        );
    }
}
export default Loading;
