import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
// import { Tooltip } from 'antd';
import "./index.scss";
import { userApi } from '@/api/api';
import token from '@/utils/token';
import { LoginView } from '@/components';
import { Images } from '@/assets';
import { withRouter } from 'react-router-dom';
import { fetchAddrList, fetchUserInfo, fetchMsgCount } from '@/redux/actions';

function TopBlock(props) {
    const dispatch = useDispatch();
    const currentAddr = useSelector(state => state.addrReducer.currentAddr);
    const addrList = useSelector(state => state.addrReducer.addrList);
    const userInfo = useSelector(state => state.userInfo.userInfo);
    const msgCount = useSelector(state => state.msgCount.msgCount);

    const [addrVisible, setAddrVisible] = useState(false);
    const [loginVisible, setLoginVisible] = useState(false);
    const [loginModalType, setLoginModalType] = useState(0);

    const isLogin = !!token.getToken(); // 是否已登录
    const isChooseAdr = props.isChooseAdr || false; // 是否可以切换地址
    const outApp = localStorage.getItem('outApp');

    useEffect(() => {
        if (isLogin) {
            dispatch(fetchAddrList());
            dispatch(fetchUserInfo());
            dispatch(fetchMsgCount());
        }
    }, []);

    function renderPrice(price) {
        if (!price) return "0";
        let num = Number(price);
        if (isNaN(num)) return "0";

        return num.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
    }
    const webappView = useRef(null);
    const webappIcon = useRef(null);
    function onMouseEnter() {
        const offsetLeft = webappView.current.offsetLeft - 10;
        webappIcon.current.style.left = offsetLeft + "px";
        webappIcon.current.style.display = "block";
    }
    function onMouseLeave() {
        webappIcon.current.style.display = "none";
    }

    const chooseAddr = (e) => {
        console.log('====', e)
        const currentItem = addrList.find((item) => item.id.toString() === e.id.toString());
        if (currentItem) {
            const param = {
                id: currentItem.id
            }
            userApi.changeAddr(param).then((res) => {
                if (res) {
                    token.setToken(res);
                    window.location.reload();
                    // dispatch(fetchAddrList());
                    // dispatch(fetchUserInfo());
                }
            });
        }
    }

    const addrMenu = (
        <div className="addr-menu">
            {addrList && addrList.map((item) => (
                <div className={currentAddr && item.id + '' === currentAddr.id + '' ? 'menu_item active' : 'menu_item'} key={item.id} onMouseDown={() => chooseAddr(item)}>
                    {item.address}
                </div>
            ))}
        </div>
    );

    const handleLoginCancel = () => {
        setLoginVisible(false);
        setLoginModalType(0);
    }

    // const showRegistModal = () => {
    //     setLoginModalType(1);
    //     setLoginVisible(true);
    // }

    const logOut = () => {
        token.removeToken();
        token.removeLoginPhone();
        if (!outApp) {
            props.history.push('/login');
        } else {
            localStorage.removeItem('outApp');
            window.location.href = window.location.origin;
        }
    }

    const goToHome = () => {
        props.history.push('/');
    }

    const goToMessage = () => {
        props.history.push('/message');
    }

    const goToMember = () => {
        props.history.push('/member');
    }

    // const qrIconDom = () => {
    //     let icon = '';
    //     if (window.location.href.indexOf('//test') > -1 || window.location.href.indexOf('//localhost') > -1) {
    //         icon = 'https://img.dmallcdn.com/dshop/202103/6bdfc931-0566-4d5f-a7d4-5b6a037c5076';
    //     } else if (window.location.href.indexOf('//dev') > -1) {
    //         icon = 'https://img.dmallcdn.com/dshop/202103/2ab8c209-4a17-4cf5-9717-5e76f0a3e92e';
    //     } else {
    //         icon = 'https://img.dmallcdn.com/dshop/202103/a2877e02-a17d-416b-88a2-ea4def24ee0d';
    //     }
    //     return icon;
    // }

    return (
        <div className="HeaderInfoContainer">
            <img
                className="webappIcon"
                ref={webappIcon}
                src={Images.topMiniProcessPic}
            />

            <div className="headerTopContainer">
                <div className="headerTopView">
                    {isLogin ? (
                        <div className="headerTopItem">
                            {isChooseAdr ? (
                                <div className="headerTopAddress">
                                    <span className="addressTip">送至：</span>
                                    <div className="addr-select" hidefocus="true" onBlur={() => setAddrVisible(!addrVisible)} outline="0" tabIndex="0">
                                        <div className={addrVisible ? "current-addr-val hover" : "current-addr-val"} onClick={() => setAddrVisible(!addrVisible)}>
                                            <span>{currentAddr && currentAddr.address}</span>
                                            {addrVisible ? <i className="triangle-down" /> : <i className="triangle-up" />}
                                        </div>
                                        {addrVisible && <div className="addr-menu-block">
                                            {addrMenu}
                                        </div>}
                                    </div>
                                </div>
                            ) : (
                                <div className="homeView" onClick={goToHome}>
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAAE6DSy/AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAHgAAAACyl2QUAAADdUlEQVRIDcVWTUgVURQ+Z3y+fP2YkEFuLFALoiCRFjNqPEjatcxFtJCCwoiI3KnLNwoiSGALo/aRBNHCIHlBgbpxFQZRFqVlFEX/+P5mbufOvDv/P74X4l3MPfec7/zcc889cwHEOHMvyUk01nKGCb4EnSMXxMKeBULMtkRQXFKWoiAMGcJ7ySBqU80CbLoVK9voa2K1wcIw+uISWGNG1EyTLq5zwT64Adw3mbUhuG7TsVS32mRsSQRank0XXaMdUGJrlhEBIgYlU+0FTVu0hB5CApTeeHj2UlYfRueBduTeJt+ia5s8hpjhSIoHmZ5ogPzf7wYXcRHmh457EKbIxzwx1gbFwisfnzMQPwFjTYasHGrCAnaN9lC6ZknZYvkIoewQUMGp5wHYHTL/m+bwLTmUTBJrCJ+yFRyn58MGMai6YX74gL0FLwjxKCVuyWKHOAg/pkTdT0u5auLiVG2crp0DL1JR5+nIZIO9XWqE7OA3L4Svgw3I6gvK8GGXAr+NUolBvrhKahOwMHSdy/05UDIrPmWOLK6vQKHYwUlyu8OY6eM+BVn9SmHvEULfzOCBl2cbkNU/5Nmy7AV61pYeVRMNWaX6ZXUeUNSyHZp73sJq9jkvZZ2Ui4SOuAReW0gNnO2ibnI5QQmZIXErMDjkhYWvSb8mcQx0XTGPUcm8rMwAmS5fZ/8xhrsNlFjZ9EmdvS092gp5jf8xfGNjEWgs9GJtzIDPr80oG6joL2NrExV8mVyQgEX65k4o/LhBZX+OTCAguwu766/Ao6u/AtCRrMoCSI83QiH3mEqmPdAq4hKkoAeyQ58teXpsH4Bm3pK9LWsw3esq+I0FoIzvB8hlyXGLZTiK4P0akiepVUiuXxTiLWrTl5yq4WXIUYp6hNI8CyxHu6hgMKCAC8tQglycVnAA3SOdUNJnyHl9nIFIOYPYBuU+gq7MadBxmhxvizRcvbBAZX+Wfqf3hQkzAEXtI8Ztcmy2VyHdtJm6qYT9MDc4haBklo3iQv6yAP52Obhpfk3D7ygLDeSzga7wR/cRVNOVK422/KIRav/dCoWhaufgWxBlDeEJ3e5JYFLeBdMhCaj3U2pPufgxi8oDSKT64NkAvW0CRvfIHF3fLwGSUNaWH8GWB+A5Ahyg1841uh7hj1qtpIXmsybJoJR/GipHas4MJ53yf+0b/x3ghuoDAAAAAElFTkSuQmCC" />
                                    <span>麦鲜达首页</span>
                                </div>
                            )}
                            {userInfo.creditCustomer && <div className="headerTopQuota">
                                剩余信用额度：
                                <span>{renderPrice(userInfo.amount) + (userInfo.creditFrozen ? "（已冻结）" : '')}</span>
                            </div>}
                        </div>
                    ) : (
                        <div className="headerTopItem">
                            <span className="unLoginText">麦德龙欢迎您!</span>
                        </div>
                    )}
                    <div className="headerTopItem">
                        {isLogin ? (
                            <div className="headerTopItem">
                                <div className="topUser">
                                    <span className="user-name-item">{userInfo.userName}，麦德龙欢迎您！</span>
                                    <div className="menu-list">
                                        <span className="menu-item" onClick={() => logOut()}>退出</span>
                                    </div>
                                </div>
                                <div className="topService">
                                    专属客服：{userInfo.customerMobile}
                                </div>
                            </div>
                        ) : (
                            <div className="unLoginView">
                                <span>您好，</span>
                                <span className="hover" onClick={() => setLoginVisible(true)}>请登录</span>
                                {/* <span style={{ margin: "0 10px" }}>或</span> */}
                                {/* <Tooltip color="#ffffff" title={<img className="qr_register_icon" src={qrIconDom()} />}>
                                    <span
                                        className="hover"
                                        // onClick={() => showRegistModal()}
                                        style={{ color: "$color-primary" }}
                                    >
                                        申请账号
                                    </span>
                                </Tooltip> */}
                            </div>
                        )}
                        <div className="topInfo">
                            <div className="topEmail" onClick={goToMessage}>
                                {msgCount ? <i></i> : null}
                                <img src={Images.topMsgPic} />
                            </div>
                            <div className="info">
                                {!outApp && <div className="_info" onClick={goToMember}>会员中心</div>}
                                <div
                                    className="_info2"
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    ref={webappView}
                                >
                                    麦鲜达小程序
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loginVisible && <LoginView closeFun={handleLoginCancel} type={loginModalType} />}
        </div>
    );
}
export default withRouter(TopBlock);
