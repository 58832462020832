import React from "react";
import "./menuListButton.scss";

function MenuListButton(props) {
    const { menuItem } = props;
    return (
        <div
            className="buttonContent"
            onClick={() => {
                const { onChangeSelect } = props;
                onChangeSelect(menuItem.index);
            }}
        >
            {props.selectIndex == menuItem.index ? (
                <div className="selectLine"></div>
            ) : (
                    <div className="whiteLine"></div>
                )}
            <a className={props.selectIndex == menuItem.index ? "selectTitle active" : "selectTitle"}>{menuItem.title}</a>
        </div>
    );
}

export default MenuListButton;
