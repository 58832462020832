import React, { useEffect, useState } from "react";
import { LoadingBox } from "@/components";
import "./goodLoop.scss";
import { GoodsItem } from "../index";
import { configApi, goodsApi } from "@/api/api";
import { Images } from "@/assets";
import { generateCancelToken } from "@/utils/index";

/**
 *   props参数
 *   type ‘similar’/不传
 *   data 数据item
 *   dataSource 数据源 1:不请求数据，但data要有数据，2:请求大数据接口获取data 不传默认为2
 *   number 每页轮播数量，不传默认为4
 *   recommendType 大数据请求类型，1-常购商品，2-自有品牌，3-行业组推荐，不传默认为1
 *   rfId 根据商品rfId请求大数据，不传默认为空
 *   bigFlag 商品模版大小 true/false
 */
const GoodLoop = (props) => {
    const [total, setTotal] = useState(0);
    const [renderData, setRenderData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showBlock, setShowBlock] = useState(true);
    const [width, setWidth] = useState(0);
    const [currentDotIdx, setCurrentDotIdx] = useState(1);

    const type = props.type;
    const GoodsNumber = props.number || 4;

    const { cancel, cancelToken } = generateCancelToken();

    const initStyle = () => {
        let goodViewWidth = 234;
        let goodViewRight = 14;

        if (props.bigFlag) {
            goodViewWidth = 256;
        }
        if (type === "similar") {
            goodViewWidth = 256;
            goodViewRight = 20;
        }
        let width =
            goodViewWidth * GoodsNumber + goodViewRight * (GoodsNumber - 1);
        setWidth(width);
    };

    const commonSetInitData = (data) => {
        setTotal(data.length);
        setAllData(data);
        const arr = [...data].slice(0, GoodsNumber);
        setRenderData(arr);
        setShowBlock(!!data.length);
    }

    const fetchData = () => {
        const params = {
            recommendType: props.recommendType || 1,
            rfId: props.rfId || "",
        };
        setLoading(true);

        if (props.requestType == "simularList") {
            // fetchGoodsByWordsOnCart
            goodsApi
                .fetchGoodsByWordsOnCart({
                    pageIndex: 1,
                    pageSize: 100,
                    backCategoryIds: props.mcCode,
                })
                .then((res) => {
                    setLoading(false);
                    if (res) commonSetInitData(res);
                })
                .catch((e) => {
                    if (!e || !e.cancel) {
                        setLoading(false);
                        setShowBlock(false);
                    }
                });
        } else {
            configApi
                .fetchRecommend(params, { cancelToken })
                .then((res) => {
                    setLoading(false);
                    if (res) {
                        commonSetInitData(res);
                    } else {
                        setShowBlock(false);
                    }
                })
                .catch((e) => {
                    if (!e || !e.cancel) {
                        setLoading(false);
                        setShowBlock(false);
                    }
                });
        }
    };

    const fetchFloor = () => {
        if (!props.data) {
            setShowBlock(false);
            return;
        }
        const params = {
            id: props.data.id,
        };
        setLoading(true);
        configApi
            .fetchFloorGoods(params, { cancelToken })
            .then((res) => {
                setLoading(false);
                if (res && res.goodsListVoDtoList) {
                    commonSetInitData(res.goodsListVoDtoList);
                } else {
                    setShowBlock(false);
                }
            })
            .catch((e) => {
                if (!e || !e.cancel) {
                    setLoading(false);
                    setShowBlock(false);
                }
            });
    };

    const initAction = () => {
        if (props.dataSource === 1) {
            fetchFloor();
        } else {
            fetchData();
        }
        initStyle();
        return () => {
            if (cancel) cancel();
        };
    };

    useEffect(() => {
        initAction();
    }, [props.isReload]);

    const prev = () => {
        if (currentDotIdx > 1) {
            let cur = currentDotIdx - 1;
            setCurrentDotIdx(cur);
            const arr = [...allData].slice((cur - 1) * GoodsNumber, cur * GoodsNumber);
            setRenderData(arr);
        }
    };
    const next = () => {
        if (currentDotIdx < (total / GoodsNumber)) {
            let cur = currentDotIdx + 1;
            setCurrentDotIdx(cur);
            const arr = [...allData].slice((cur - 1) * GoodsNumber, cur * GoodsNumber);
            setRenderData(arr);
        }
    };

    return (
        <div
            className="recommendContainer"
            style={{ display: showBlock ? "block" : "none" }}
        >
            <div className="goodsModule">
                {props.title && (
                    <div
                        className={
                            props.titleColor
                                ? "moduleTitle " + props.titleColor
                                : "moduleTitle"
                        }
                    >
                        {props.title}
                    </div>
                )}
                <div className="moduleContainer">
                    {props.image && (
                        <div className="moduleBanner">
                            <img src={props.image} />
                        </div>
                    )}
                    {!loading ? (
                        <div
                            className="moduleGoodsView"
                            style={{ width: width + "px" }}
                        >
                            {total > GoodsNumber && (
                                <img
                                    className="moduleOption left"
                                    onClick={prev}
                                    src={Images.leftPic1}
                                />
                            )}
                            {total > GoodsNumber && (
                                <img
                                    className="moduleOption right"
                                    onClick={next}
                                    src={Images.rightPic1}
                                ></img>
                            )}
                            <div className="moduleGoodsList">
                                {renderData &&
                                    renderData.map((item, index) => {
                                        return (
                                            <div
                                                className={
                                                    type === "similar"
                                                        ? "moduleGoodsBlock similarGoodsBlock"
                                                        : "moduleGoodsBlock"
                                                }
                                                key={"block" + index}
                                            >
                                                <GoodsItem
                                                    {...props}
                                                    className={item.skuId === allData[total - 1].skuId ? "lasView" : ""}
                                                    goods={item}
                                                    key={item.skuId}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    ) : (
                        <LoadingBox loading={loading} />
                    )}
                </div>
            </div>
        </div>
    );
};
export default GoodLoop;
