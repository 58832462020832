import React from "react";
import "./orderList.scss";
import OrderListHeader from "./components/orderListHeader";
import OneOrderList from "./components/oneOrderList";
import OrderEmptyView from "./components/orderEmptyView";
import { Pagination } from "antd";
import { LoadingBox } from "../index";
function OrderList(props) {
    function changePagination(page) {
        props.changeCurrent(page);
    }
    function itemRender(current, type, originalElement) {
        if (type === "prev") {
            return (
                <div
                    className="pageButton"
                    onClick={() => {
                        if (current == 0) return;
                        changePagination(props.current - 1);
                    }}
                >
                    <span className="pageButtonText">上一页</span>
                </div>
            );
        }
        if (type === "next") {
            return (
                <div
                    className="pageButton"
                    onClick={() => {
                        if (current * 10 > props.totalCount) {
                            return;
                        }
                        changePagination(props.current + 1);
                    }}
                >
                    <span>下一页</span>
                </div>
            );
        }
        return originalElement;
    }

    function emptyTitle() {
        switch (props.status) {
            case "waitAudit":
                return "待审核";
            case "waitMetroAudit":
                return "待麦德龙确认";
            case "waitCustomerConfirm":
                return "待客户确认";
            case "picking":
                return "备货中";
            case "delivery":
                return "待收货";
            case "completed":
                return "交易成功";
            case "canceled":
                return "交易关闭";
            default:
                return "全部状态";
        }
    }

    return (
        <div className="orderCenterList">
            <OrderListHeader changeOrderStatus={props.changeOrderStatus} />

            {props.loading && (
                <div className="orderCetnerLoading">
                    <LoadingBox loading={props.loading} />
                </div>
            )}

            {props.list.length === 0 && !props.loading && (
                <OrderEmptyView title={emptyTitle()} />
            )}

            {!props.loading &&
                props.list.map((item, index) => {
                    return (
                        <OneOrderList
                            detailList={item.detail}
                            info={item.order}
                            key={index}
                        />
                    );
                })}
            {!props.loading && props.list.length !== 0 && (
                <div className="pageNumView">
                    <Pagination
                        current={props.current}
                        defaultCurrent={1}
                        itemRender={itemRender}
                        onChange={changePagination}
                        showSizeChanger={false}
                        total={props.totalCount}
                    />
                </div>
            )}
        </div>
    );
}

export default OrderList;
