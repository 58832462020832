import React from "react";
import { useHistory } from "react-router-dom";
import { Images } from "../../../assets/index";
import { Image } from "antd";
import "./saleListItem.scss";
function SaleListItem(props) {
    const { singleItem = false, showPrice = false } = props;
    const history = useHistory();

    return (
        <div className="saleListItemContent">
            <div className="saleNameDetail">
                {/* <div className="headImage"></div> */}
                <div className="headImage">
                    <Image
                        fallback={Images.defaultGoodsPic}
                        preview={false}
                        src={
                            props.item.pictureUrl + "?imageView2/2/w/182" ||
                            Images.defaultGoodsPic
                        }
                    />
                </div>

                <div className="itemName">
                    【{props.item.goodsRfid}】{props.item.goodsName}
                </div>
            </div>
            {showPrice && (
                <div className="headItem">
                    ￥{props.item.priceShow.toFixed(2)}
                </div>
            )}
            <div className="headItem numItem">
                {props.item.weight == 1
                    ? props.item.weightQty
                    : props.item.numberQty}
            </div>
            {showPrice && (
                <div className="headItem">
                    ￥{props.item.totalPriceShow.toFixed(2)}
                </div>
            )}
            {singleItem ? (
                <div
                    className={
                        props.info.auditStateDes == "待审批"
                            ? "headItem headItemRed"
                            : "headItem headItemGray"
                    }
                >
                    {props.info.auditStateDes}
                </div>
            ) : (
                <div className="headItem"></div>
            )}
            {singleItem ? (
                <div className="headItem operation">
                    <div className="operationView">
                        {props.info.auditStateDes != "待审批" && (
                            <a
                                className="operationButton"
                                onClick={() => {
                                    history.push(
                                        `/approve/detail/${props.info.id}`
                                    );
                                }}
                            >
                                查看详情
                            </a>
                        )}

                        {props.info.auditStateDes == "待审批" && (
                            <a
                                className="operationButton"
                                onClick={() => {
                                    history.push(
                                        `/approve/detail/${props.info.id}`
                                    );
                                }}
                            >
                                审批
                            </a>
                        )}
                    </div>
                </div>
            ) : (
                <div className="headItem"></div>
            )}
        </div>
    );
}

export default SaleListItem;
