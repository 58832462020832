import Axios from "axios";
import { Message } from "antd";
import token from "./token";
import { switchCard, showReEntry } from "@/components";
import { host } from "../api/host";

const errCodeList = [
    "2011405", // 当前地址送货商场状态异常
    "2011406", // 用户手机号不支持FSD业务
    "2011407", // 用户地址不存在
    "2011408", // 用户卡不存在
    "2031029", // 用户企业信息不支持FSD业务
];

const axios = Axios.create({
    baseURL: host, // 'http://test-akte-gateway.dmall.com',
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
});

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        const { config } = response;
        const { message, code, data, type } = response.data;

        if (type == "text/xml") {
            return response.data;
        } else if (code === "1000") {
            // 请求成功code
            return data;
        } else if (code === "2021000") {
            // 系统维护时间
            if (message) {
                sessionStorage.setItem('sysErrImg', message);
            }
            const url = window.location.href.split("#");
            window.location.href = url[0] + "#/sysErr";
        } else if (code === "2031010") {
            // 未选择卡code
            if (window.location.href.indexOf("login") === -1) {
                if (!switchCard.getFlag()) {
                    switchCard.show();
                }
            }
        } else if (code === '2011423') {
            if (!!localStorage.getItem('outApp')) {
                if (!showReEntry.getFlag()) {
                    showReEntry.show({ msg: message });
                }
            } else {
                token.removeToken();
                const url = window.location.href.split("#");
                window.location.href = url[0] + "#/login";
            }
        } else if (
            errCodeList.indexOf(code) > -1 &&
            (!config || !config.noTips)
        ) {
            // 账号信息出现异常，弹出提示并点击退出登录
            if (!showReEntry.getFlag()) {
                showReEntry.show({ msg: message });
            }
        } else {
            if (!config || !config.noTips) {
                Message.error(message);
            }
        }
        if (config && config.errAll) {
            return Promise.reject(response.data);
        }
        return Promise.reject(message);
    },
    (error) => {
        if (Axios.isCancel(error)) {
            return Promise.reject({ cancel: true });
        }
        if (error.response) {
            const { message, status } = error.response.data;
            if (status === 401) {
                if (!!localStorage.getItem('outApp')) {
                    if (!showReEntry.getFlag()) {
                        showReEntry.show({ msg: message });
                    }
                } else {
                    token.removeToken();
                    const url = window.location.href.split("#");
                    window.location.href = url[0] + "#/login";
                }
            } else {
                Message.error(message);
            }
        } else {
            Message.error("网络崩溃了～");
        }
        return Promise.reject(error);
    }
);
export default axios;
