import React from "react";
import "./breadLink.scss";
import { Breadcrumb } from "antd";
import { Images } from "../../assets/index";

function BreadLink(props) {
    const { title, link } = props;
    return (
        <div className="breakLink">
            <Breadcrumb
                separator={
                    <img
                        src={Images.moreArrow}
                        style={{ width: 11, height: 11, marginTop: -2 }}
                    />
                }
            >
                <Breadcrumb.Item>
                    <a href="">首页</a>
                </Breadcrumb.Item>
                {title && (
                    <Breadcrumb.Item>
                        <a href={link}>{title}</a>
                    </Breadcrumb.Item>
                )}
                {props.children}
            </Breadcrumb>
        </div>
    );
}

export default BreadLink;
