/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Button, Modal, message, Input } from "antd";
import "./detailStatus.scss";
import { orderApi, shopCartApi } from "../../api/api";
import throttle from "lodash/throttle";
import { useHistory } from "react-router";
import { Images } from "../../assets/index";
import { useDispatch } from "react-redux";
import { fetchStatistic } from "@/redux/actions";

const maskStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
};

function DetailStatus(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const outApp = localStorage.getItem("outApp");

    const [cancelModalShow, setCancelModalShow] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const [delayTime, setDelayTime] = useState("");
    const [successCartGoodsList, setSuccessList] = useState([]);
    const [errorCartGoodsList, setErrorList] = useState([]);
    const [sendEmailModal, ShowSendEmailModal] = useState(false);
    const [email, setEmail] = useState("");

    function statusInfo(orderStatusDes, remark = "-") {
        switch (orderStatusDes) {
            case "待审核":
                return "需尽快审阅";
            case "待麦德龙审核":
                return "等待麦德龙审核";
            case "待客户确认":
                return "等待客户确认";
            case "备货中":
                return "订单备货中，请安心等待";
            case "待收货":
                return "订单已发货，请安心等待";
            case "交易成功":
                return "如果没有收到货，可以联系专属销售经理";
            case "交易关闭":
                return `关闭原因：${remark}`;
            default:
                break;
        }
    }

    function addOneMoreOrder() {
        orderApi
            .oneMoreOrder({
                orderId: props.id,
            })
            .then((res) => {
                if (res) {
                    setAddModalShow(true);
                    dispatch(fetchStatistic());
                    const { successCartGoodsList, errorCartGoodsList } = res;
                    setSuccessList(successCartGoodsList || []);
                    setErrorList(errorCartGoodsList || []);
                }
            })
            .catch((err) => {
                console.log("onMoreOrder err", err);
            });
    }

    function addMore() {
        const addMoreDelay = throttle(() => {
            addOneMoreOrder();
        }, 500);
        addMoreDelay();
    }

    function jumpAfterSaleCreate() {
        let data = JSON.stringify({
            orderId: props.id,
            groupOrderId: props.orderId,
        });
        history.push("/aftersaleCreate/" + data);
    }

    function cancelOrder() {
        setCancelModalShow(true);
    }

    function donwloadOrder() {
        orderApi
            .getOrderExcel({
                orderId: parseInt(props.id),
            })
            .then((res) => {
                if (res) {
                    window.open(res);
                }
            });
    }

    function sendOrderShow() {
        ShowSendEmailModal(true);
    }

    function checkSignPhoto() {
        const { imgs } = props.preOrderDto.receiptDto;
        if (imgs && imgs.length != 0) {
            imgs.forEach((item) => {
                window.open(item, "_blank");
            })
        }
    }

    function cancelOrderRequest() {
        orderApi
            .cancelOrder({
                id: parseInt(props.id),
            })
            .then((res) => {
                if (res) {
                    message.info("取消成功！");
                    history.go(0);
                }
            });
    }

    function testEmail() {
        const pattern =
            /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return pattern.test(email);
    }

    useEffect(() => {
        if (props.preOrderDto.orderStateDesByPc == "待审核") {
            orderApi
                .orderTime({
                    orderId: parseInt(props.id),
                })
                .then((res) => {
                    setDelayTime(res);
                });
        }
    }, [props.preOrderDto.orderStateDesByPc]);

    useEffect(() => {
        setEmail(props.email);
    }, [props.email]);

    useEffect(() => {
        let timer = setTimeout(() => {
            setDelayTime(delayTime - 60000);
        }, 60000);
        return () => {
            clearTimeout(timer);
        };
    });

    function formatDelayTime(time) {
        if (!time) return;
        let difMinutes = parseInt((time / (60 * 1000)) % 60);
        let diffHours = parseInt((time / (60 * 60 * 1000)) % 24);
        let diffDays = parseInt(time / (24 * 60 * 60 * 1000));
        if (diffDays) {
            return (
                "剩余" +
                diffDays +
                "天" +
                diffHours +
                "小时" +
                difMinutes +
                "分钟"
            );
        }
        if (diffHours) {
            return "剩余" + diffHours + "小时" + difMinutes + "分钟";
        }
        return "剩余" + difMinutes + "分钟";
    }

    return (
        <div className="detailStatusContent">
            <div className="header">
                <span>当前状态：</span>
                <span className="headerStatus">
                    {props.preOrderDto.orderStateDesByPc}
                    {props.preOrderDto.receiptStatusDes && <span>,{props.preOrderDto.receiptStatusDes}</span>}
                </span>
            </div>
            <div className="content">
                {props.preOrderDto.orderStateDesByPc == "待审核" && (
                    <div className="delayTimeView">
                        <img
                            className="delayTimeIcon"
                            src={Images.delayTimIcon}
                        />
                        <span className="delayTimeText">
                            {formatDelayTime(delayTime)}
                        </span>
                        <span>后，系统关闭</span>
                    </div>
                )}

                <div className="statusInfo">
                    <div className="info-title-icon"></div>
                    <div className="statusInfoText">
                        {statusInfo(
                            props.preOrderDto.orderStateDesByPc,
                            props.remark
                        )}
                    </div>
                </div>

                {props.preOrderDto.orderStateDesByPc == "待审核" ||
                props.preOrderDto.orderStateDesByPc == "待麦德龙确认" ||
                props.preOrderDto.orderStateDesByPc == "待客户确认" ? (
                    <div className="statusInfo">
                        <div className="info-title-icon"></div>
                        <span className="infoText">如果您不想买,可以</span>
                        <Button
                            className="cancelButton"
                            onClick={cancelOrder}
                            size="small"
                        >
                            取消订单
                        </Button>
                    </div>
                ) : null}

                <div className="statusInfo">
                    <div className="info-title-icon"></div>
                    <span className="infoText">如果订单量不足，您还可以</span>
                    <a className="moreOrderButton" onClick={addMore}>
                        <span>再来一单</span>
                    </a>
                </div>
                {!outApp &&
                props.preOrderDto.orderStateDesByPc == "交易成功" ? (
                    <div className="statusInfo">
                        <div className="info-title-icon"></div>
                        <span className="infoText">
                            如果货品有质量问题,可以
                        </span>
                        <Button onClick={jumpAfterSaleCreate}>申请售后</Button>
                    </div>
                ) : null}
                <div className="statusInfo">
                    <div className="info-title-icon"></div>
                    <span className="infoText">您还可以</span>
                    <Button
                        className="sendButton"
                        onClick={donwloadOrder}
                        size="small"
                    >
                        下载订单
                    </Button>
                    <Button
                        className="sendButton"
                        onClick={sendOrderShow}
                        size="small"
                    >
                        发送邮箱
                    </Button>
                    {props.preOrderDto.receiptDto &&
                        props.preOrderDto.receiptDto.receiptStatus == 1 &&
                        props.preOrderDto.receiptDto.imgs && (
                            <Button
                                className="sendButton"
                                onClick={checkSignPhoto}
                                size="small"
                            >
                                查看签收快照
                            </Button>
                        )}
                </div>
            </div>
            <Modal
                footer={null}
                maskStyle={maskStyle}
                onCancel={() => {
                    setCancelModalShow(false);
                }}
                title="取消订单"
                visible={cancelModalShow}
                width={408}
                wrapClassName="cartModel"
            >
                <div className="delectTipView">
                    <img src={Images.deleteTipPic} />
                    <span>确认取消订单吗？</span>
                </div>
                <div className="cartModelButton">
                    <button
                        className="cancelBtn"
                        onClick={() => {
                            setCancelModalShow(false);
                        }}
                        type="small"
                    >
                        取消
                    </button>
                    <button
                        className="submitBtn"
                        onClick={() => {
                            setCancelModalShow(false);
                            cancelOrderRequest();
                        }}
                        type="small"
                    >
                        确认
                    </button>
                </div>
            </Modal>
            <Modal
                footer={null}
                maskStyle={maskStyle}
                onCancel={() => {
                    setAddModalShow(false);
                }}
                title="再来一单"
                visible={addModalShow}
                width={408}
                wrapClassName="cartModel"
            >
                <div className="addTipView">
                    {/* <img src={Images.greenCorrectPic} /> */}
                    <span>
                        共
                        {successCartGoodsList.length +
                            errorCartGoodsList.length}
                        件商品加入购物车，成功{successCartGoodsList.length}
                        条，失败
                        <span style={{ color: "#FE3B30" }}>
                            {errorCartGoodsList.length}
                        </span>
                        条。
                    </span>
                </div>
                <div className="cartModelButton">
                    <button
                        className="cancelBtn"
                        onClick={() => {
                            setAddModalShow(false);
                        }}
                    >
                        取消
                    </button>
                    <button
                        className="submitBtn"
                        onClick={() => {
                            // setAddModalShow(false);
                            // 去结算页
                            // history.push("/cart");
                            shopCartApi.exportAddLog().then((res) => {
                                const url = window.URL.createObjectURL(
                                    new Blob([res])
                                );
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                    "download",
                                    "再来一单加购物车结果.xlsx"
                                );
                                document.body.appendChild(link);
                                link.click();
                            });
                        }}
                    >
                        导出明细
                    </button>
                </div>
            </Modal>
            <Modal
                footer={null}
                maskStyle={maskStyle}
                onCancel={() => {
                    ShowSendEmailModal(false);
                }}
                title="发送订单"
                visible={sendEmailModal}
                width={408}
                wrapClassName="cartModel"
            >
                <div className="addTipView">
                    <span>收件邮箱</span>
                    <Input
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        value={email}
                    ></Input>
                </div>
                <div className="cartModelButton">
                    <button
                        className="sendSubmitBtn"
                        onClick={() => {
                            if (!testEmail()) {
                                message.info("邮件格式错误");
                                return;
                            }
                            orderApi
                                .sendEmailWithOrder({
                                    orderId: parseInt(props.id),
                                    eaddress: email,
                                })
                                .then(() => {
                                    message.success("发送成功");
                                    ShowSendEmailModal(false);
                                })
                                .catch(() => {
                                    ShowSendEmailModal(false);
                                });
                        }}
                    >
                        确定
                    </button>
                    <button
                        className="sendCancelBtn"
                        onClick={() => {
                            ShowSendEmailModal(false);
                        }}
                    >
                        取消
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default DetailStatus;
