import React, { useState, useEffect, useRef } from "react";
import "./detailList.scss";
import { Popover, Image } from "antd";
import { Images } from "../../../assets/index";
import { useHistory } from "react-router-dom";
import DetailSubListItem from "./detailSubListItem";

function DetailList(props) {
    console.log("detailList", props.item);
    const remark = props.item.goodRemark;

    const content = <div className="toolTipRemark">{remark}</div>;

    const [needMore, setNeedMore] = useState(false);
    const domRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        const height = parseInt(getComputedStyle(domRef.current).height);
        setNeedMore(height > 36);
    }, []);

    function jumpGoodsDetail() {
        history.push("/goodsDetail/" + props.item.goodsRfid);
    }

    const listItemClass =
        props.item.yn == -1
            ? "orderDetailListItem disabled"
            : "orderDetailListItem";

    return (
        <div className="orderDetailListItemContainer">
            <div className={listItemClass}>
                {/* {props.item.yn == -1 && ( */}

                {/* <div className="orderDetailDeleteTag">
                    <span>删除</span>
                </div> */}
                {/* <div className="orderDetailReplaceTag">
                    <span>替换</span>
                </div> */}
                {/* <div className="orderDetailAddTag">
                    <span>新增</span>
                </div> */}
                {/* )} */}
                {props.item.yn == -1 ? (
                    <div className="orderDetailDeleteTag">
                        <span>删除</span>
                    </div>
                ) : props.item.sourceWareId == null &&
                  props.item.originWareIds != null ? (
                    <div className="orderDetailReplaceTag">
                        <span>替换</span>
                    </div>
                ) : null}

                <div className="itemHead" onClick={jumpGoodsDetail}>
                    <div className="headImage">
                        <Image
                            fallback={Images.defaultGoodsPic}
                            preview={false}
                            src={
                                props.item.pictureUrl + "?imageView2/2/w/182" ||
                                Images.defaultGoodsPic
                            }
                        />
                    </div>

                    <a className="itemName">
                        【{props.item.goodsRfid}】{props.item.goodsName}
                    </a>
                </div>
                <div className="itemType itemText">
                    <span>{props.item.method || "-"}</span>
                </div>
                <div className="itemPrice itemText">
                    {props.item.priceType == "牌面价"
                        ? `￥${props.item.markPriceShow.toFixed(2)}`
                        : `￥${props.item.priceShow.toFixed(2)}`}
                </div>
                <div className="itemCount itemText">
                    {props.item.weight == 1
                        ? props.item.weightQty
                        : props.item.numberQty}
                </div>
                <div className="itemDiscount itemText">
                    {props.item.discountPriceShow.toFixed(2)
                        ? `￥${props.item.discountPriceShow.toFixed(2)}`
                        : "-"}
                </div>
                <div className="itemAmount itemText">
                    ￥{props.item.totalPriceShow.toFixed(2)}
                </div>
                <div className="itemRemark itemText">
                    <div className="showItem">
                        <div className="remarkText ellipsis">
                            {remark || "-"}
                        </div>
                        {needMore && (
                            <div className="moreButton">
                                <Popover
                                    content={content}
                                    placement="bottom"
                                    title=""
                                    trigger="click"
                                >
                                    <a className="moreText"></a>
                                </Popover>
                            </div>
                        )}
                    </div>

                    <div className="contentCopy" ref={domRef}>
                        {remark}
                    </div>
                </div>
            </div>
            {props.item.childObj &&
                props.item.childObj.map((item, index) => {
                    return <DetailSubListItem item={item} key={index} />;
                })}
        </div>
    );
}

export default DetailList;
