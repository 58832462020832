export default {
    margeState: (preState, newState) => {
        return {
            ...preState,
            ...newState
        }
    },
    computedStringLength: (val = '') => {
        let len = 0;
        for (let i = 0; i < val.length; i++) {
            if (val.charCodeAt(i) > 127 || val.charCodeAt(i) === 94) {
                len += 2;
            } else {
                len++
            }
        }
        return len;
    },
    formatNumber: (num) => {
        const numBak = num || 0;
        return numBak > 99 ? '99+' : numBak;
    },
    urlParam: (queryParamStr) => {
        const params = {};
        if (queryParamStr) {
            const paramsArr = queryParamStr.split("&");
            paramsArr.forEach(item => {
                const par = item.split('=');
                params[par[0]] = par[1];
            })
        }
        return params;
    }
}