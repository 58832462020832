import React from 'react';
import token from '@/utils/token';
import { showReEntry } from '@/components';
import './reEntryModal.scss';

const ReEntryModal = (props) => {
    const outApp = localStorage.getItem('outApp');
    const logout = () => {
        showReEntry.hide();
        token.removeToken();
        const url = window.location.href.split('#');
        if (!outApp) {
            window.location.href = url[0] + '#/login';
        } else {
            localStorage.removeItem('outApp');
            window.location.href = window.location.origin;
        }
    }

    return (
        <div className="re-entry-modal">
            <div className="re-entry-body">
                <div className="msg-tips">{props.msg}</div>
                <div className="logout-btn" onClick={logout}>{!outApp ? '退出' : '确定'}</div>
            </div>
        </div>
    );
}

export default ReEntryModal;