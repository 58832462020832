import React from 'react';
import { Modal } from 'antd';
import './index.scss';

const CommonModal = (props) => {
    return (
        <Modal
            centered
            className={"common-modal " + props.className}
            footer={null}
            onCancel={props.handleModalCancel}
            title={props.title}
            visible={props.visible}
            width={props.width}
            zIndex={props.zIndex || 1000}
        >
            {props.children}
            {props.footer
                ? props.footer
                : <div className="btn-box">
                    <div className="cancel-btn" onClick={props.handleModalCancel}>取消</div>
                    <div className="confirm-btn" onClick={props.handleModalOk}>确定</div>
                </div>
            }
        </Modal>
    );
}

export default CommonModal;