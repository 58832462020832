/* eslint-disable react/jsx-max-props-per-line */
import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import AnimatedRouter from "react-animated-router";
import routes from './routeConfig';
import asyncComponent from "./lazy";
const NotFound = asyncComponent(() => import("@/page/notFound/index"));

const BasicRoute = () => (
    <HashRouter>
        <Switch>
            {
                routes.map((route, index) => {
                    if (route.meta.userSys && !!localStorage.getItem('outApp')) {
                        return null;
                    }
                    return (
                        <Route
                            exact={route.exact}
                            key={index}
                            path={route.path}
                            render={(props) => {
                                document.title = route.meta.title;
                                return (
                                    <route.component {...props} />
                                );
                            }}
                        />
                    )
                })
            }
            <Route component={NotFound} />
        </Switch>
    </HashRouter>
);

export default BasicRoute;
