const initState = {
    collectNum: 0,
    orderNum: 0,
    cartNum: 0
}

const statisticReducer = (state = initState, action) => {
    switch (action.type) {
        case 'setStatisticPoint':
            // eslint-disable-next-line no-case-declarations
            const newState = action.payload;
            return { ...state, ...newState };
        default:
            return state || {};
    }
}

export default statisticReducer;