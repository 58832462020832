import React from "react";
// import { Steps } from "antd";
import Steps from "../../components/steps/index";
import StepItem from "../../components/steps/stepItem/index";
import "./orderDetailStep.scss";
import moment from "moment";

function OrderDetailStep(props) {
    function formatTime(index) {
        if (props.infoList[index]) {
            return moment(props.infoList[index].time).format(
                "YYYY-MM-DD HH:mm"
            );
        }
        return null;
    }

    return (
        <div className="orderDetailStepContent">
            <Steps
                className="stepsStyle"
                current={props.infoList.length - 1}
                size="small"
            >
                <StepItem
                    description={formatTime(0)}
                    title="提交订单"
                ></StepItem>
                <StepItem
                    description={formatTime(1)}
                    title="订单审核"
                ></StepItem>
                <StepItem
                    description={formatTime(2)}
                    title="商场备货"
                ></StepItem>
                <StepItem
                    description={formatTime(3)}
                    title="商场发货"
                ></StepItem>
                <StepItem
                    description={formatTime(4)}
                    title="完成送货"
                ></StepItem>
            </Steps>
        </div>
    );
}

export default OrderDetailStep;
