import React from "react";
import "./menuList.scss";
import MenuListButton from "./components/menuListButton";
class menuList extends React.Component {
    constructor(props) {
        super(props);
    }

    changeSelectIndex(index) {
        this.props.changeMenuIndex(index);
    }

    render() {
        // 是否外部对接项目
        const outApp = localStorage.getItem('outApp');

        let titleList = [
            {
                title: "订单中心",
                index: 0,
            },
            {
                title: "审批列表",
                index: 1,
            },
            {
                title: "售后列表",
                index: 2,
            },
            {
                title: "我的收藏",
                index: 3,
            },
            {
                title: "会员卡管理",
                index: 4,
            },
            {
                title: "消息中心",
                index: 5,
            },
        ];
        if (!!outApp) {
            titleList = [
                {
                    title: "订单中心",
                    index: 0,
                },
                {
                    title: "我的收藏",
                    index: 3,
                },
                {
                    title: "消息中心",
                    index: 5,
                },
            ];
        }
        return (
            <div className="menuList">
                {titleList.map((value, index) => {
                    return (
                        <MenuListButton
                            key={index}
                            menuItem={value}
                            onChangeSelect={this.changeSelectIndex.bind(this)}
                            selectIndex={this.props.selectIndex || 0}
                        />
                    );
                })}
            </div>
        );
    }
}

export default menuList;
