// 售后列表
import React, { useState } from "react";
import "./saleList.scss";
import moment from "moment";
import SaleListItem from "./components/saleListItem.jsx";
import { Images } from "../../assets/index";

function SaleList(props) {
    const { showPrice = false } = props;
    const [showAll, setShowAll] = useState(false);
    const header = (
        <div className="saleHeader">
            {props.info.orderId ? (
                <div className="orderNumView">
                    <div className="orderNumTitle">
                        订单编号：{props.info.orderId}
                    </div>
                </div>
            ) : null}
            <div className="orderTime">
                申请时间：
                {moment(props.info.created).format("YYYY-MM-DD HH:mm")}
            </div>
            {/* <div className="orderSource">{props.channelTypeDesc}</div> */}
        </div>
    );

    return (
        <div className="saleListContent">
            <div className="approveListContent">
                {header}
                {props.list.map((item, index) => {
                    if (!showAll && index >= 3) return;
                    return (
                        <SaleListItem
                            info={props.info}
                            item={item}
                            key={index}
                            reload={props.baseSearch}
                            showPrice={showPrice}
                            singleItem={index === 0}
                        />
                    );
                })}
            </div>
            {props.list.length > 3 ? (
                showAll ? (
                    <div className="bottomView">
                        <div className="bottomButton">
                            <a
                                onClick={() => {
                                    setShowAll(false);
                                }}
                            >
                                收起
                                <img src={Images.listUpArrow} />
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="bottomView">
                        <div className="bottomButton">
                            <span>共</span>
                            <span className="bottomNum">
                                {props.list.length}
                            </span>
                            <span>种商品，</span>
                            <a
                                onClick={() => {
                                    setShowAll(true);
                                }}
                            >
                                点击查看
                                <img src={Images.listDownArrow} />
                            </a>
                        </div>
                    </div>
                )
            ) : null}
        </div>
    );
}

export default SaleList;
