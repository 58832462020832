import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";
import { Images } from "@/assets";
import { useSelector, useDispatch } from "react-redux";
import { fetchStatistic } from "@/redux/actions";
import Steps from "../../../steps/index";
import StepItem from "../../../steps/stepItem/index";
import { goodsApi } from '@/api/api';
import { generateCancelToken } from '@/utils/index';

const MainBlock = (props) => {
    const [searchVal, setSearchVal] = useState("");
    const [hotKeys] = useState();
    const [resultList, setResultList] = useState([]);
    const [selectPannelShow, setSelectPannelShow] = useState(false);
    const [timer, setTimer] = useState(null);

    // 初始化生成cancelToken实例和cancel方法
    const { cancel, cancelToken } = generateCancelToken();

    const dispatch = useDispatch();
    const pointList = useSelector((state) => state.statistic);

    const initData = () => {
        const { keywords, type } = props.match.params;
        if (type === "search") {
            setSearchVal(decodeURIComponent(keywords) || "");
        }
        // setHotKeys(["大米", "面", "油", "冻品"]);
        dispatch(fetchStatistic());
    };

    useEffect(() => {
        initData();
        return () => {
            if (cancel) cancel();
            if (timer) clearTimeout(timer);
        }
    }, []);

    const searchData = (val = searchVal) => {
        const value = val || "大豆油";
        props.history.push(
            "/goodsList/" + encodeURIComponent(value) + "/search/" + new Date().getTime()
        );
    };

    const toSearchHot = (val) => {
        setSearchVal(val);
        searchData(val);
    };

    const jumpPage = (type) => {
        switch (type) {
            case 1:
                props.history.push("/collect");
                break;
            case 2:
                props.history.push("/order");
                break;
            case 3:
                props.history.push("/cart");
                break;
            default:
                break;
        }
    };

    const goToHome = () => {
        props.history.push("/");
    };

    const handleBlur = () => {
        setSelectPannelShow(false);
    }

    const handleInputChange = (val) => {
        setSearchVal(val);
        if (timer) clearTimeout(timer);
        let timeOut = setTimeout(() => {
            clearTimeout(timeOut);
            if (val) {
                const params = {
                    page: 1,
                    pageSize: 100,
                    keyword: decodeURIComponent(val),
                }
                goodsApi.fetchGoodsByWords(params, { cancelToken }).then(res => {
                    let resultList = [];
                    if (res.records) {
                        resultList = res.records.splice(0, 10);
                        setSelectPannelShow(true);
                    }
                    setResultList(resultList);
                }).catch((e) => {
                    if (!e || !e.cancel) {
                        setResultList([]);
                    }
                });
            } else {
                if (cancel) cancel();
                setResultList([]);
            }
        }, 500);
        setTimer(timeOut);
    }

    const handleFocus = () => {
        setSelectPannelShow(resultList.length > 0);
        // if (searchVal) handleInputChange(searchVal);
    }

    const keyPress = (e) => {
        if (e.charCode === 13) {
            searchData();
        }
    };

    const renderResult = (item) => {
        return item.replace(
            searchVal,
            `<span style="color: #f4413c;">${searchVal}</span>`
        );
    };

    const chooseOption = (item) => {
        setSearchVal(item.goodsAlias || item.goodsName);
        setSelectPannelShow(false);
    }

    return (
        <div
            className={
                props.type === "small"
                    ? "header-main-block small"
                    : props.type === "small-fixed"
                        ? "header-main-block small small-fixed"
                        : "header-main-block"
            }
        >
            <div className="logo-box" onClick={goToHome}>
                <img src={Images.logoPic} />
            </div>
            {props.process ? (
                <div style={{ width: "600px" }}>
                    <Steps
                        bottom
                        current={0}
                        style={{
                            marginRight: "-50px",
                        }}
                    >
                        <StepItem title="提交订单" />
                        <StepItem title="商场接单" />
                        <StepItem title="商场配送" />
                        <StepItem title="完成送货" />
                    </Steps>
                </div>
            ) : null}
            {!props.process && !props.noSearch && (
                <div className="search-box">
                    <div className="search-input">
                        <div className="select-input-box">
                            <input
                                onBlur={handleBlur}
                                onChange={(e) => handleInputChange(e.target.value)}
                                onFocus={handleFocus}
                                onKeyPress={keyPress}
                                placeholder="大豆油"
                                type="text"
                                value={searchVal}
                            ></input>
                            {selectPannelShow && resultList.length > 0 && <div className="select-pannel">
                                {resultList.map((item) => (
                                    <div
                                        className="option-item"
                                        dangerouslySetInnerHTML={{ __html: renderResult(item.goodsAlias || item.goodsName) }}
                                        key={item.rfId}
                                        onMouseDown={() => chooseOption(item)}
                                    ></div>
                                ))}
                            </div>}
                        </div>
                        <div
                            className="search-btn"
                            onClick={() => searchData()}
                        >
                            搜索
                        </div>
                    </div>
                    <div className="hot-keywords">
                        {hotKeys &&
                            hotKeys.map((item, index) => (
                                <span
                                    key={index}
                                    onClick={() => toSearchHot(item)}
                                >
                                    {item}
                                </span>
                            ))}
                    </div>
                </div>
            )}
            {!props.process && (
                <div className="point-box">
                    <div className="point-item" onClick={() => jumpPage(1)}>
                        <div className="point-img-item">
                            <img src={Images.collectPic} />
                            {/* <div className="point-num">{pointList.collectNum}</div> */}
                        </div>
                        <div className="point-name-item">收藏</div>
                    </div>
                    <div className="point-item" onClick={() => jumpPage(2)}>
                        <div className="point-img-item">
                            <img src={Images.orderPic} />
                            <div className="point-num">
                                {pointList.orderNum}
                            </div>
                        </div>
                        <div className="point-name-item">订单</div>
                    </div>
                    <div className="point-item" onClick={() => jumpPage(3)}>
                        <div className="point-img-item">
                            <img src={Images.topcartPic} />
                            <div className="point-num">{pointList.cartNum}</div>
                        </div>
                        <div className="point-name-item">购物车</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withRouter(MainBlock);
