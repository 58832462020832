import React, { useState } from "react";
import "./orderListHeader.scss";
import { Dropdown, Menu } from "antd";
import { Images } from "../../../assets/index";

function OrderListHeader(props) {
    const [status, setStatus] = useState("全部状态");

    const menu = (
        <Menu>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("全部状态");
                        props.changeOrderStatus(null);
                    }}
                >
                    全部状态
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("待审核");
                        props.changeOrderStatus("waitAudit");
                    }}
                >
                    待审核
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("待麦德龙确认");
                        props.changeOrderStatus("waitMetroAudit");
                    }}
                >
                    待麦德龙确认
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("待客户确认");
                        props.changeOrderStatus("waitCustomerConfirm");
                    }}
                >
                    待客户确认
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("备货中");
                        props.changeOrderStatus("picking");
                    }}
                >
                    备货中
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("待收货");
                        props.changeOrderStatus("delivery");
                    }}
                >
                    待收货
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("交易成功");
                        props.changeOrderStatus("completed");
                    }}
                >
                    交易成功
                </a>
            </Menu.Item>
            <Menu.Item>
                <a
                    className="statusButtonText"
                    onClick={() => {
                        setStatus("交易关闭");
                        props.changeOrderStatus("canceled");
                    }}
                >
                    交易关闭
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="headerContent">
            <div className="headerTitleView">
                <div className="title">商品</div>
            </div>
            <div className="headerPrice">
                <div className="title">单价</div>
            </div>
            <div className="headerCount">
                <div className="title">数量</div>
            </div>
            <div className="headerAmount">
                <div className="title">小计</div>
            </div>
            <div className="headerStatus">
                <Dropdown
                    overlay={menu}
                    overlayStyle={{
                        height: 40,
                        fontSize: 12,
                        lineHeight: 14,
                    }}
                >
                    <div className="orderListHeaderDropView">
                        <a className="title">{status}</a>
                        <img
                            className="orderListHeaderdropIcon"
                            src={Images.cartAddressDown}
                        />
                    </div>
                </Dropdown>
            </div>
            <div className="headerOperation">
                <div className="title">操作</div>
            </div>
        </div>
    );
}

export default OrderListHeader;
