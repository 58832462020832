let host = ''

const href = window.location.href;
let EVT = '';

const _evt = () => {
    if (href.indexOf('https://') > -1) {
        // 测试环境
        if (
            href.indexOf('https://test') > -1 &&
            href.indexOf('https://test.') == -1 ||
            href.indexOf('https://localhost') > -1
        ) {
            EVT = 'https://test';
            // dev环境
        } else if (
            href.indexOf('https://dev') > -1 &&
            href.indexOf('https://dev.') == -1
        ) {
            EVT = 'https://dev';
        } else {
            EVT = 'https://';
        }
        return EVT;
    } else {
        // 测试环境
        if (
            href.indexOf('http://test') > -1 &&
            href.indexOf('http://test.') == -1 ||
            href.indexOf('http://localhost') > -1
        ) {
            EVT = 'http://test';
            // dev环境
        } else if (
            href.indexOf('http://dev') > -1 &&
            href.indexOf('http://dev.') == -1
        ) {
            EVT = 'http://dev';
        } else {
            EVT = 'http://';
        }
        return EVT;
    }
}

host = _evt() + 'akte-gateway.dmall.com';

export { host, EVT };