import Constants from "./Constants";

export default {
    //默认商品图片地址
    defaultGoodsPic: `${Constants.defaultPicDomain}/dshop/202009/328dc1b1-ea78-483a-b183-afa97ac40084`,
    // 小程序图标
    miniProcessPic: `${Constants.defaultPicDomain}/dshop/202206/43f9a569-7bd8-4aff-8967-76737c43d0a9`,
    // 分类图标
    catePic: `${Constants.defaultPicDomain}/dshop/202008/71d0ce50-7bce-4601-b9af-de9b47727cea`,
    // left1图标
    leftPic1: `${Constants.defaultPicDomain}/dshop/202008/85bebace-e7d8-426b-8e80-912667e66c42`,
    // left2图标
    leftPic2: `${Constants.defaultPicDomain}/dshop/202008/f8f5ab66-89ea-4766-902c-96b1e0032a9c`,
    // right1图标
    rightPic1: `${Constants.defaultPicDomain}/dshop/202008/9a547da2-42e2-4fa2-ad43-fdefe7bde8b5`,
    // right2图标
    rightPic2: `${Constants.defaultPicDomain}/dshop/202008/be860859-25c7-46b9-88bc-fb6d65341f25`,
    // 购物车图标
    cartPic: `${Constants.defaultPicDomain}/dshop/202008/7d21e895-5f4c-4b50-a860-c08e6b846a09`,
    // 头部logo图标
    logoPic: `${Constants.defaultPicDomain}/dshop/202206/0cc8bec4-bec9-48a9-8d59-83416e680dbe`,
    // 收藏图标
    collectPic: `${Constants.defaultPicDomain}/dshop/202008/de821c9b-2d85-43f5-a0d0-0f356306730d`,
    // 订单图标
    orderPic: `${Constants.defaultPicDomain}/dshop/202008/24e726a1-fb9d-4aed-b050-24e5c4af572c`,
    // 头部购物车图标
    topcartPic: `${Constants.defaultPicDomain}/dshop/202008/a87d4c7e-1e0e-493a-92a4-19aba0386025`,
    // 头部小程序图标
    topMiniProcessPic: `${Constants.defaultPicDomain}/dshop/202206/67b9d5c8-f049-45a3-88fa-a59bdcbb79dd`,
    // 头部邮箱图标
    topMsgPic: `${Constants.defaultPicDomain}/dshop/202008/1dc3cb5f-ca8e-44db-bf79-0d2bad259a20`,
    // modal close图标
    closePic: `${Constants.defaultPicDomain}/dshop/202007/4c7ac408-de15-4a00-997e-16a7515531ea`,
    // errorTip图标
    errorTipPic: `${Constants.defaultPicDomain}/dshop/202008/682735a6-022e-4e65-a70d-d567fbd90f93`,
    // phone图标
    phonePic: `${Constants.defaultPicDomain}/dshop/202008/f3f9db78-b37e-4d95-a2e8-e44d0630e0aa`,
    // 验证码图标
    codePic: `${Constants.defaultPicDomain}/dshop/202008/943f6a1d-bdd0-4269-a125-41333848df4b`,
    // 验证码发送成功图标
    codeSendPic: `${Constants.defaultPicDomain}/dshop/202008/ab457d6c-4585-4b30-9a5e-9d0d4f3f6073`,
    // 404图标
    notFoundPic: `${Constants.defaultPicDomain}/dshop/202010/c04dacaa-1051-4bf1-9d46-55e9f5a03baf`,
    // cart404图标
    cartNotFoundPic: `${Constants.defaultPicDomain}/dshop/202010/f0250910-92e0-4dc2-981c-a2d91d2090f4`,
    // 收藏成功图标
    colletSuccessPic: `${Constants.defaultPicDomain}/dshop/202008/7a42a374-c8ec-4f42-b26c-8ac8df2ef1cd`,
    // 删除提示图标
    deleteTipPic: `${Constants.defaultPicDomain}/dshop/202008/ff1e3259-65cd-470b-bae1-9713abd2d99b`,
    // 收藏编辑图标
    collectEditPic: `${Constants.defaultPicDomain}/dshop/202009/8c0fc929-fb72-4691-ace9-c11287c98da9`,
    // 一键加车图标
    addShoppingPic: `${Constants.defaultPicDomain}/dshop/202010/c7cf32e6-fdc1-4bea-9080-dabb4ce8ca92`,
    // 选中图标
    choosedPic: `${Constants.defaultPicDomain}/dshop/202009/30f447d5-9979-4a77-a11e-2dd5768c5354`,
    // 麦德龙banner
    bannerPic: `${Constants.defaultPicDomain}/dshop/202009/703b7f4a-5226-4f2f-899a-a10ed9055fd5`,
    // 麦德龙Doc
    docPic1: `${Constants.defaultPicDomain}/dshop/202009/956b6939-60c4-44c3-a837-0484d7bb464b`,
    // 麦德龙Doc
    docPic2: `${Constants.defaultPicDomain}/dshop/202009/a0f37fac-0fad-47de-b3bf-5bde7274a73a`,
    // 商品详情sup
    supPic: `${Constants.defaultPicDomain}/dshop/202008/c8ec093a-9b38-4e70-aa3c-998ff18daefd`,
    // 商品详情加入购物车图标
    addCartPic: `${Constants.defaultPicDomain}/dshop/202011/344340d2-4c19-4419-b84b-da6c22fdbcdc`,
    // 商品详情未收藏图标
    addCollectPic: `${Constants.defaultPicDomain}/dshop/202011/03df67cf-d8aa-4ae2-8057-ab128b30e380`,
    // 商品详情已收藏图标
    collectedPic: `${Constants.defaultPicDomain}/dshop/202011/903ef0fd-236d-4ea8-98b4-e439ec2e7769`,
    // 没有消息图标
    noMsgPic: `${Constants.defaultPicDomain}/dshop/202008/7afcda00-d684-42fb-beb3-1a40ea506467`,
    // navbar 收藏亮
    lightCollectPic: `${Constants.defaultPicDomain}/dshop/202011/6fad413c-c3f4-429c-ab50-e1c883514898`,
    // navbar 收藏暗
    greyCollectPic: `${Constants.defaultPicDomain}/dshop/202011/90f592ba-4a9f-41a1-97fe-f1772cac5c4e`,
    // navbar 购物车亮
    lightCartPic: `${Constants.defaultPicDomain}/dshop/202011/89214c9a-ff59-4346-87f5-b59e153c1277`,
    // navbar 购物车暗
    greyCartPic: `${Constants.defaultPicDomain}/dshop/202011/3b2cd18a-cc6e-448e-b813-51655c183049`,
    // navbar 回到顶部亮
    lightTopPic: `${Constants.defaultPicDomain}/dshop/202011/af0cb0d6-8467-471c-ab79-e16e14e0193e`,
    // navbar 回到顶部暗
    greyTopPic: `${Constants.defaultPicDomain}/dshop/202011/2a4f90e2-2a50-42c4-8f4e-5d7ab5f78841`,
    // login 大图片
    loginBigPic: `${Constants.defaultPicDomain}/dshop/202011/65d06e10-1aa7-4536-bf32-a3ba18cd8161`,
    greenCorrectPic: `${Constants.defaultPicDomain}/dshop/202011/8b205330-5b69-4555-af3d-8bc5e2410aad`,
    // activity no content
    noActivityImg: `${Constants.defaultPicDomain}/dshop/202011/1c519957-9413-4f73-8e01-5dd3f5eab921`,
    // 订单空白页
    orderEmpty: `${Constants.defaultPicDomain}/dshop/202012/3ee07f08-e284-44f9-b1b1-0f734ccff7b6`,
    // 信用支付 ICON
    creditIcon: `${Constants.defaultPicDomain}/dshop/202012/2753b8b4-9d2b-4cde-84f6-c5cdd0df72de`,
    // 延迟ICON
    delayTimIcon: `${Constants.defaultPicDomain}/dshop/202012/bb0d2d1b-b5ad-4df2-941f-b516b67674c4`,
    // 购物车选择地址下拉
    cartAddressDown: `${Constants.defaultPicDomain}/dshop/202012/034c553e-c583-4350-a970-3665065a53af`,
    // list展开
    listDownArrow: `${Constants.defaultPicDomain}/dshop/202012/0e86339c-bfda-40c9-88d4-cae969363250`,
    // list收起
    listUpArrow: `${Constants.defaultPicDomain}/dshop/202012/96547abf-a6b1-4965-b31a-4b73918b9f00`,
    // 面包屑下一级
    moreArrow: `${Constants.defaultPicDomain}/dshop/202012/add3892a-940f-462c-9ad6-a60ca2c075e5`,
    // 系统维护
    defaultSysErrImg: `${Constants.defaultPicDomain}/dshop/202202/dc59905b-67e5-463b-bcb0-428e16e6de6f`,
};
