import { userApi, msgApi, statisticApi } from '@/api/api';
import { Tools } from '@/utils';

const fetchAddrList = () => {
    return (dispatch) => {
        (() => {
            userApi.fetchUserInfo().then(res => {
                if (res && res.companyDistributionAddressList) {
                    const current = res.companyDistributionAddressList.find(item => item.id.toString() === res.companyDistributionAddressId.toString());
                    dispatch({ type: 'setAddrList', payload: res.companyDistributionAddressList });
                    dispatch({ type: 'setCurrentAddr', payload: current });
                } else {
                    dispatch({ type: 'setAddrList', payload: [] });
                    dispatch({ type: 'setCurrentAddr', payload: {} });
                }
            });
        })();
    }
}

const fetchUserInfo = () => {
    return (dispatch) => {
        (() => {
            userApi.fetchTitleUserInfo().then(res => {
                dispatch({ type: 'setUserInfo', payload: res || {} });
            });
        })();
    }
}

const fetchMsgCount = () => {
    return (dispatch) => {
        (() => {
            msgApi.getMessageCount().then(res => {
                if (res && res.length) {
                    let count = 0;
                    const countJson = {};
                    res.forEach(item => {
                        count += item.unreadCount;
                        countJson[item.messageType] = Tools.formatNumber(item.unreadCount);
                    });

                    dispatch({ type: 'setMsgCount', payload: { msgCount: count, typeCount: countJson } });
                } else {
                    dispatch({ type: 'setMsgCount', payload: { msgCount: 0, typeCount: {} } });
                }
            });
        })();
    }
}

const fetchStatistic = () => {
    return (dispatch) => {
        (() => {
            statisticApi.getFavOrderCartCount().then((res) => {
                if (res) {
                    dispatch({
                        type: 'setStatisticPoint',
                        payload: {
                            collectNum: Tools.formatNumber(res.favoriteCount),
                            orderNum: Tools.formatNumber(res.orderCount),
                            cartNum: Tools.formatNumber(res.cartGoodsCount)
                        }
                    });
                } else {
                    dispatch({ type: 'setStatisticPoint', payload: { collectNum: 0, orderNum: 0, cartNum: 0 } });
                }
            })
        })();
    }
}

export {
    fetchAddrList,
    fetchUserInfo,
    fetchMsgCount,
    fetchStatistic
}