import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { COLLECT_API } from '@/api/api';
import { CommonModal, LoadingBox } from '@/components';
import { generateCancelToken } from '@/utils/index';
import { Message } from 'antd';

const CollectDetail = (props) => {
    const [tabsKey, setTabsKey] = useState(1);
    const [title, setTitle] = useState('');
    const [collectData, setCollectData] = useState([]);
    const [selectedCollect, setSelectedCollect] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const pageSize = 10;
    const listRef = useRef(null);

    const { cancel, cancelToken } = generateCancelToken();

    const fetchCollect = () => {
        const params = {
            page: page,
            pageSize: pageSize
        }
        setLoading(true);
        COLLECT_API.getCollectList(params, { cancelToken }).then(res => {
            setLoading(false);
            if (res) {
                setCollectData(collectData => collectData.concat(res.list || []));
                setTotal(res.totalCount);
            }
        }).catch((e) => {
            if (!e || !e.cancel) setLoading(false);
        });
    };

    const handleScroll = () => {
        let isScrollBottom = parseInt(listRef.current.scrollHeight) - (parseInt(listRef.current.scrollTop) + parseInt(listRef.current.clientHeight))
        if (isScrollBottom <= 10 && !loading && collectData.length < total) {
            setPage(page => page + 1);
        }
    }

    useEffect(() => {
        if (props.visible && listRef.current) {
            listRef.current.addEventListener('scroll', handleScroll);
        }
        return () => listRef.current && listRef.current.removeEventListener('scroll', handleScroll);
    }, [props.visible, collectData, total, loading]);

    useEffect(() => {
        if (props.visible) {
            fetchCollect();
        }
        return () => { if (cancel) cancel(); }
    }, [props.visible, page]);

    useEffect(() => {
        if (props.visible) {
            setCollectData([]);
            setSelectedCollect([]);
            setTabsKey(1);
            setPage(1);
            setTotal(0);
        }
    }, [props.visible]);

    const changeTabs = (type) => {
        setTabsKey(type);
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const chooseCollect = (item) => {

        const arr = [...selectedCollect];
        if (arr.indexOf(item) > -1) {
            arr.splice(arr.indexOf(item), 1);
        } else {
            if (props.single) {
                arr[0] = item;
            } else {
                arr.push(item);
            }
        }
        setSelectedCollect(arr);
    }

    const handleCancel = () => {
        setTitle('');
        setTabsKey(1);
        setCollectData([]);
        setSelectedCollect([]);
        setPage(1);
        setTotal(0);
        props.handleCancel();
    }

    const handleOk = () => {
        if (tabsKey === 1) {
            if (!selectedCollect || !selectedCollect.length) {
                if (props.single) Message.warn('请选择收藏夹');
                else Message.warn('至少选择一个收藏夹');
                return;
            } else {
                if (props.single) {
                    props.handleOk(1, selectedCollect[0]);
                } else {
                    props.handleOk(1, selectedCollect);
                }
            }
        } else {
            if (!title) {
                Message.warn('请填写收藏夹名称');
                return;
            } else {
                props.handleOk(2, title);
            }
        }
    }

    return (
        <CommonModal
            className="change-collect-modal"
            handleModalCancel={handleCancel}
            handleModalOk={handleOk}
            title={props.title}
            visible={props.visible}
            width={408}
        >
            <div className="transfer-collect-block">
                <div className="tabs-box">
                    <span
                        className={tabsKey === 1 ? "active" : ""}
                        onClick={() => changeTabs(1)}
                    >
                        选择收藏夹
                </span>
                    <span
                        className={tabsKey === 2 ? "active" : ""}
                        onClick={() => changeTabs(2)}
                    >
                        新增收藏夹
                </span>
                </div>
                {tabsKey === 1 ?
                    <div className="collect-box" ref={listRef}>
                        {loading ? <LoadingBox /> :
                            collectData && collectData.map((item) => {
                                return (
                                    <div
                                        className={selectedCollect && selectedCollect.length && selectedCollect.find(child => child.id === item.id) ?
                                            "collect-name-item active" : "collect-name-item"}
                                        key={item.id}
                                        onClick={() => chooseCollect(item)}
                                    >
                                        {item.favoriteName}
                                    </div>
                                );
                            })}
                    </div> :
                    <div className="input-box">
                        <div className="label-item">名称：</div>
                        <div className="input-item">
                            <textarea maxLength={15} onChange={handleTitleChange} placeholder="请填写您的收藏列表标题，15字以内" value={title} />
                        </div>
                    </div>}
            </div>
        </CommonModal>
    );
}

export default CollectDetail;