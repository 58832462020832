import React, { useEffect, useState } from 'react';
import { CardBlock, switchCard } from '@/components';
import { Message, Spin } from 'antd';
import { userApi } from '@/api/api';
import token from "@/utils/token";
import './switchCardsModal.scss';
// import { CloseOutlined } from '@ant-design/icons';
import { generateCancelToken } from '@/utils/index';

const SwitchCardsModal = (props) => {
    const [cardList, setCardList] = useState([]);
    const [chooseCardIdx, setChooseCardIdx] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [cardLoading, setCardLoading] = useState(false);

    const { cancel, cancelToken } = generateCancelToken();

    const fetchCardsData = () => {
        setCardLoading(true);
        userApi.fetchCards({ cancelToken }).then(res => {
            if (res.cardInfoDtoList) {
                setCardList(res.cardInfoDtoList);
                setChooseCardIdx(res.cardInfoDtoList.map(item => item.userId));
            }
            setCardLoading(false);
        }).catch((e) => {
            if (!e || !e.cancel) setCardLoading(false);
        });
    }

    useEffect(() => {
        fetchCardsData();
        return () => { if (cancel) cancel(); }
    }, []);

    const chooseCard = (userId) => {
        const index = chooseCardIdx.indexOf(userId);
        if (index > -1) {
            const arr = [...chooseCardIdx];
            arr.splice(index, 1);
            setChooseCardIdx(arr);
        } else {
            const arr = [...chooseCardIdx];
            arr.push(userId);
            setChooseCardIdx(arr);
        }
    };

    const switchCards = () => {
        const params = {
            userIds: chooseCardIdx
        };
        userApi.changeCard(params, { cancelToken }).then((res) => {
            token.setToken(res.token);
            const { isView } = props;
            if (isView) {
                // 登陆成功之后的跳转
                let redirectUrl = '/';
                if (props.location.state) {
                    const pathName = props.location.state.from.pathname;
                    redirectUrl = (pathName === '/login' ? '/' : pathName);
                }
                props.history.push(redirectUrl);
            } else {
                if (window.location.href.indexOf('login') > -1) {
                    const url = window.location.href.split('#');
                    window.location.href = url[0] + '#/';
                } else {
                    window.location.reload();
                }
            }
            setBtnLoading(false);
            switchCard.hide();
        }).catch(e => {
            if (!e || !e.cancel) setBtnLoading(false);
        });
    }

    const goToShopping = () => {
        if (btnLoading) return;
        if (!chooseCardIdx.length) {
            Message.error('请选择会员卡');
            return;
        }
        setBtnLoading(true);
        switchCards();
    }

    return (
        <div className="switch-cards-modal">
            <div className="switch-cards-content">
                <div className="header-box">
                    <header className="title">选择会员卡</header>
                    {/* <CloseOutlined onClick={() => switchCard.hide()} /> */}
                </div>
                <div className={cardList && cardList.length > 0 ? "card-panel" : "card-panel card-center"}>
                    {cardList && cardList.length > 0 ?
                        <div
                            className={
                                cardList.length === 1
                                    ? "card-box single-box"
                                    : "card-box"
                            }
                        >
                            {cardList &&
                                cardList.map((item) => (
                                    <CardBlock
                                        active={chooseCardIdx.indexOf(item.userId) > -1}
                                        chooseCard={() => chooseCard(item.userId)}
                                        index={item.userId}
                                        item={item}
                                        key={item.userId}
                                    />
                                ))}
                        </div> :
                        <div>{cardLoading ? <Spin className="loading-box" /> : <span>暂无数据</span>}</div>
                    }
                </div>
                <div className="btn-box">
                    <div className="toShopping-btn" onClick={() => goToShopping()}>开始购物</div>
                </div>
            </div>
        </div>
    );
}

export default SwitchCardsModal;