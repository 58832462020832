import React from "react";
import "./detailOrderList.scss";
import DetailList from "./components/detailList";
function DetailOrderList(props) {
    return (
        <div className="detailOrderListContent">
            <div className="listHeader">
                <div className="listName">商品</div>
                <div className="listType">加工方式</div>
                <div className="listPrice">单价</div>
                <div className="listCount">数量</div>
                <div className="listDiscount">优惠</div>
                <div className="listAmount">小计</div>
                <div className="listRemarks">备注</div>
            </div>
            <div className="listContent">
                {props.orderList.map((item, index) => {
                    return <DetailList item={item} key={index} />;
                })}
            </div>
            <div className="listFooter">
                <div>
                    <span>合计：</span>
                    <span className="priceType">￥</span>
                    <span className="listAmountPrice">
                        {props.preOrderDto.totalPriceShow &&
                            props.preOrderDto.totalPriceShow.toFixed(2)}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default DetailOrderList;
