import Tools from './tools';
import axios from 'axios';

const generateCancelToken = () => {
    let cancel = null;
    const cancelToken = new axios.CancelToken(c => {
        cancel = c;
    });
    return {
        cancel,
        cancelToken
    };
};

export {
    Tools,
    generateCancelToken
}