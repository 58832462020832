import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Images } from '@/assets';
import moment from 'moment';
import { loginApi } from '@/api/api';
import './index.scss';
import { host } from '@/api/host';

const CodeModal = (props) => {
    const [codeErr, setCodeErr] = useState('');
    const [codeInput, setCodeInput] = useState('');
    const [imgCode, setImgCode] = useState('');

    const handleCodeChange = (e) => {
        setCodeInput(e.target.value);
    }

    const hideCodeModal = () => {
        setCodeErr('');
        setCodeInput('');
        props.hideCodeModal();
    }

    const refreshCode = () => {
        const phone = props.phone;
        const imgCodeVal = host + "/user/acquireImageCode?mobile=" + phone + "&time=" + moment().valueOf();
        setImgCode(imgCodeVal);
    }

    useEffect(() => {
        if (props.visible) {
            refreshCode();
        }
    }, [props.visible]);

    const showCode = () => {
        const href = window.location.href;
        let flag = false;
        if (href.indexOf('https://') > -1) {
            // 测试环境
            if (
                href.indexOf('https://test') > -1 &&
                href.indexOf('https://test.') == -1 ||
                href.indexOf('https://localhost') > -1
            ) {
                flag = true;
            } else if ( // dev环境
                href.indexOf('https://dev') > -1 &&
                href.indexOf('https://dev.') == -1
            ) {
                flag = true;
            }
            return flag;
        } else {
            // 测试环境
            if (
                href.indexOf('http://test') > -1 &&
                href.indexOf('http://test.') == -1 ||
                href.indexOf('http://localhost') > -1
            ) {
                flag = true;
            } else if ( // dev环境
                href.indexOf('http://dev') > -1 &&
                href.indexOf('http://dev.') == -1
            ) {
                flag = true;
            }
            return flag;
        }
    }

    const validateCode = () => {
        if (!codeInput) {
            setCodeErr('请输入图形验证码');
            return;
        }

        const params = {
            mobile: props.phone,
            code: codeInput,
            needSupportFsd: props.needSupportFsd || 0,
        };

        if (showCode()) {
            params.id = 999;
        }

        loginApi
            .sendSms(params)
            .then((res) => {
                if (props.callBack) props.callBack(res && res.selectedAgree ? res.selectedAgree : 0);
                hideCodeModal();
            })
            .catch((err) => {
                if (!err.cancel) {
                    setCodeErr(err);
                }
            });
    }

    return (
        <Modal
            className="code-modal"
            footer={null}
            getContainer={props.id ? document.getElementById(props.id) : document.body}
            onCancel={() => hideCodeModal()}
            visible={props.visible}
        >
            <div className="code-box">
                {codeErr && (
                    <div className="erropTip">
                        <div className="errorTipView">
                            <img src={Images.errorTipPic}></img>
                            <span>{codeErr}</span>
                        </div>
                    </div>
                )}
                <div className="input">
                    <div className="left-input">
                        <div className="iconView">
                            <img src={Images.codePic} />
                        </div>
                        <input
                            onChange={(e) => handleCodeChange(e)}
                            placeholder="请输入图形验证码"
                            value={codeInput}
                        />
                    </div>
                    <span
                        className="imgCodeView"
                        onClick={() => refreshCode()}
                    >
                        <img src={imgCode} />
                    </span>
                </div>
                <div
                    className="button"
                    onClick={() => validateCode()}
                >
                    确认
                </div>
            </div>
        </Modal>
    );
}

export default CodeModal;