import React from 'react';
import './index.scss';
import { withRouter } from 'react-router-dom';
import { Images } from '@/assets';

const NotFoundRes = (props) => {
    const goToHome = () => {
        props.history.push('/');
    }
    return (
        <div className="not-found-res">
            <div className="icon-box">
                <img src={Images.notFoundPic} />
            </div>
            <div className="text-box">
                <span className="code">404</span>
                <span className="tips">抱歉，您访问的页面不存在</span>
                <div className="btn" onClick={() => goToHome()}>返回首页</div>
            </div>
        </div>
    );
}

export default withRouter(NotFoundRes);