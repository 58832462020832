/* eslint-disable react/no-multi-comp */
import React from "react";
import { Message, Image } from "antd";
import { withRouter } from "react-router-dom";
import "./goodsItem.scss";
import systemCode from "@/utils/code";
import { shopCartApi } from "@/api/api";
import { Images } from "@/assets";
import token from "@/utils/token";
import { useDispatch } from "react-redux";
import { fetchStatistic } from "@/redux/actions";

const GoodsItem = (props) => {
    const dispatch = useDispatch();

    const goodsData = props.goods || {};
    const flag = props.flag || false;
    const isLogin = goodsData.loginFlag || token.getToken() || false;
    const bigDiv = props.bigFlag
        ? "goods-item-block bigDiv "
        : "goods-item-block ";
    const divClass = props.hasBorder ? bigDiv : bigDiv + "noBorder ";

    const ifOver = goodsData.sellGoodsFlag && goodsData.status === 1; // 是否售罄
    const ifExpired = goodsData.status === 0; // 是否失效
    const noBuy = ifExpired || ifOver; // 是否可购买

    const goToDetail = () => {
        if (goodsData.status === 0) return;
        props.history.push("/goodsDetail/" + goodsData.rfId);
    };

    const addCart = (e) => {
        e.stopPropagation();
        if (goodsData.defaultSellType) {
            goToDetail();
        } else {
            const params = {
                skuId: goodsData.skuId,
                buyCount: 1,
            };
            shopCartApi.addGoodToCart(params).then(() => {
                Message.success("成功加入购物车");
                dispatch(fetchStatistic());
                if (props.callBack) props.callBack();
            });
        }
    };

    const priceBlock = () => {
        return (
            <div className="goods-price-cart">
                {!isLogin && (
                    <span className="no-login-price">登录查看专属价格</span>
                )}
                {isLogin && (
                    <div className="goods-price">
                        {goodsData.priceType === 1 && (
                            <span className="price-tags">
                                {systemCode[goodsData.priceType]}
                            </span>
                        )}
                        <span
                            className={
                                noBuy ? "price-num over-text" : "price-num"
                            }
                        >
                            {"¥" + ((+goodsData.calPrice || 0).toFixed(2))}
                        </span>
                    </div>
                )}
                {isLogin && !noBuy && (
                    <span className="goods-cart" onClick={addCart}>
                        <img
                            className="goods-cart-button"
                            src={Images.cartPic}
                        />
                    </span>
                )}
            </div>
        );
    };

    return (
        <div
            className={props.className ? divClass + props.className : divClass}
        >
            <div className="goods-img" onClick={goToDetail}>
                <Image
                    className={goodsData.imgUrl ? "img" : "small-img"}
                    fallback={Images.defaultGoodsPic}
                    preview={false}
                    src={
                        goodsData.imgUrl
                            ? goodsData.imgUrl + '?imageView2/2/w/182'
                            : Images.defaultGoodsPic
                    }
                />
                {ifOver && (
                    <div className="mask">
                        <div className="tips">补货中</div>
                    </div>
                )}
                {ifExpired && (
                    <div className="mask">
                        <div className="tips">已下架</div>
                    </div>
                )}
            </div>
            {flag && priceBlock()}
            <div
                className={noBuy ? "goods-title over-text" : "goods-title"}
                onClick={goToDetail}
            >
                【{goodsData.rfId}】
                {goodsData.goodsAlias || goodsData.goodsName}
            </div>
            {!flag && priceBlock()}
            <div className="goods-tags">
                {goodsData.promotionTypes &&
                    goodsData.promotionTypes.length > 0 && (
                        <div className="discount-tags">
                            {goodsData.promotionTypes[0]}
                        </div>
                    )}
                {goodsData.collectFlag && !goodsData.buyFlag && (
                    <div className="collect-tags">收藏过</div>
                )}
                {goodsData.buyFlag && (
                    <div className="collect-tags">购买过</div>
                )}
            </div>
            <div className="specs-item">
                {goodsData.packageNum && goodsData.chine &&
                    (
                        <span>
                            规格：{goodsData.packageNum || ""}
                            {goodsData.chine || ""}
                        </span>
                    )
                }
            </div>
            {goodsData.taxRate && (
                <div className="tax-rate-item">
                    税率：{goodsData.taxRate * 100 + "%"}
                </div>
            )}
        </div>
    );
};

export default withRouter(GoodsItem);
