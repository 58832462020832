import React, { useState, useEffect } from "react";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import "./brand.scss";

function brand (props) {
    const {title, options, value} = props;
    const [open, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(value || []);

    useEffect(() => {
      props.onChange && props.onChange(selectedOptions);
    }, [selectedOptions]);
    const handleOpenChange = (open) => {
      setOpen(open);
    }
    const handleBrandClick = (item) => {
      console.log(item);
      if (selectedOptions && selectedOptions.filter((option) => option.key === item.key).length > 0) {
        setSelectedOptions(selectedOptions.filter((option) => option.key !== item.key));
      } else {
        setSelectedOptions([...selectedOptions, item]);
      }
    }

    const onReset = () => {
      setSelectedOptions([]);
      props.onReset && props.onReset();
    }

    const handleConfirm = () => {
      props.onConfirm && props.onConfirm(selectedOptions);
      setOpen(false);
    }

    // 自定义渲染下拉菜单
    // eslint-disable-next-line react/no-multi-comp
    const customDropdownRender = () => (
      <div className="brand">
          <div className="brand-content">
              {options.map((item, index) => (
                  <div className={`item ${selectedOptions && selectedOptions.filter((option) => option.key === item.key).length > 0 ? 'brand__active' : 'brand__normal'}`} key={index} onClick={() => handleBrandClick(item)}>{item.name}</div>
              ))}
          </div>
          <div className="bottom">
            <div className="bottom-container">
              <div className="reset bottom-container__btn" onClick={() => onReset()}>重置</div>
              <div className="confirm bottom-container__btn" onClick={() => handleConfirm()}>确定</div>
            </div>
          </div>
      </div>
    );
    return (
      <Dropdown arrow className="container" dropdownRender={customDropdownRender} menu={{ items: options, selectable: true, multiple: true}} onOpenChange={handleOpenChange} open={open} trigger={['click']}>
        <a>
          <Space>
            {title}
            <DownOutlined />
          </Space></a>
      </Dropdown>
    );
}

export default brand;