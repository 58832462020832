import React, { useEffect, useState } from "react";
import "./index.scss";
import MainBlock from "./components/mainBlock/index";
import TopBlock from "./components/topBlock/index";
import Category from "../category";

const Header = (props) => {
    const [isFixed, setIsFixed] = useState(false);

    const handleScoller = () => {
        const scrollTop =
            document.documentElement.scrollTop ||
            window.pageYOffset ||
            document.body.scrollTop;
        if (scrollTop > 220) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    };

    useEffect(() => {
        if (!!props.isFix) {
            window.addEventListener("scroll", handleScoller);
        }
        return () => {
            window.removeEventListener("scroll", handleScoller);
        };
    }, [props.isFix]);

    return (
        <div
            className={
                props.isCategory
                    ? "header-block no-border"
                    : props.process
                    ? "header-block bottom-line"
                    : "header-block"
            }
        >
            <TopBlock isChooseAdr={props.isChooseAdr} />
            {isFixed && (
                <div
                    style={{
                        height: props.type === "small" ? "90px" : "138px",
                    }}
                ></div>
            )}
            <div className={isFixed ? "main-fixed" : ""}>
                <MainBlock
                    noSearch={props.noSearch}
                    process={props.process}
                    type={isFixed ? "small-fixed" : props.type}
                />
            </div>
            {props.isCategory && <Category />}
        </div>
    );
};

export default Header;
