/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useRef, useEffect } from "react";
import "./category.scss";
import { goodsApi } from '@/api/api';
import { withRouter } from "react-router-dom";
import { Images } from "@/assets";
import { generateCancelToken } from '@/utils/index';

function Category(props) {
    const [menus, setMenus] = useState();
    const [menuChildren, setMenuChildren] = useState();
    const categoryBlockRef = useRef(null);
    const categoryPanelRef = useRef(null);

    const { cancel, cancelToken } = generateCancelToken();

    function getCategory() {
        goodsApi.fetchAllCategory({ cancelToken }).then(res => {
            if (res && res.length) {
                setMenus(res);
                setMenuChildren(res[0].children);
            }
        });
    }

    useEffect(() => {
        getCategory();
        return () => { if (cancel) cancel(); }
    }, []);

    function onCategoryBlockMouseEnter() {
        categoryPanelRef.current.style.display = "flex";
    }
    function onCategoryBlockMouseLeave() {
        categoryPanelRef.current.style.display = "none";
    }

    const goToGoodsList = (item) => {
        props.history.push('/goodsList/' + item.code + '/cate/' + new Date().getTime());
    }

    const showChildrens = (item) => {
        setMenuChildren(item.children);
    }

    return (
        <div className="categoryContainer">
            <div className="positionView">
                <div className="categoryView">
                    <div
                        className="categoryBlock"
                        onMouseEnter={onCategoryBlockMouseEnter}
                        onMouseLeave={onCategoryBlockMouseLeave}
                        ref={categoryBlockRef}
                    >
                        <img src={Images.catePic} />
                        <span>商品分类</span>
                    </div>
                </div>
                <div
                    className="categoryPanel"
                    onMouseEnter={onCategoryBlockMouseEnter}
                    onMouseLeave={onCategoryBlockMouseLeave}
                    ref={categoryPanelRef}
                >
                    <div className="catalogContainer">
                        <div className="catalogContainerView">
                            {menus && menus.map((item) => {
                                return (
                                    <div
                                        className={`catalogBlock`}
                                        key={item.code}
                                        onClick={() => goToGoodsList(item)}
                                        onMouseEnter={() =>
                                            showChildrens(item)
                                        }
                                    >
                                        <span>{item.name}</span>
                                        <i></i>
                                    </div>
                                );
                            })}
                            <div className="lastBlock"></div>
                        </div>
                    </div>
                    <div className="classificationContainer">
                        {menuChildren && menuChildren.map((item) => {
                            return (
                                <div
                                    className="classificationBlock"
                                    key={item.code}
                                >
                                    <div className="classificationBlockParent" onClick={() => goToGoodsList(item)}>
                                        {item.name}
                                    </div>
                                    <div className="classificationBlockChildren">
                                        {item.children && item.children.map((_item) => {
                                            return (
                                                <div
                                                    className="classificationBlockChild"
                                                    key={_item.code}
                                                    onClick={() => goToGoodsList(_item)}
                                                >
                                                    {_item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(Category);
