import React from "react";
import "./index.scss";

const StepItem = (props) => {
    // title：标题
    // description: 描述
    // bottom 在底部
    return (
        <div
            className={props.done ? "progressItem done" : "progressItem"}
            style={{ width: props.width ? props.width + "px" : "" }}
        >
            {!props.bottom && (
                <div
                    className={
                        props.done ? "progressTitle done" : "progressTitle"
                    }
                >
                    {props.title || "标题"}
                </div>
            )}
            <div
                className={
                    props.size == "small" ? "progressBodySmall" : "progressBody"
                }
            >
                {props.stepIndex != 0 && (
                    <div
                        className={
                            props.done
                                ? "progressLeftLine done"
                                : "progressLeftLine"
                        }
                    ></div>
                )}
                <h3>
                    {props.stepIndex == props.current ? "✓" : props.stepNumber}
                </h3>
                {!props.isLast && (
                    <div
                        className={
                            props.done
                                ? "progressRightLine done"
                                : "progressRightLine"
                        }
                    ></div>
                )}
            </div>
            {props.bottom && (
                <div
                    className={
                        props.done ? "progressTitle done" : "progressTitle"
                    }
                >
                    {props.title || "标题"}
                </div>
            )}
            <div className="progressDescription">{props.description}</div>
        </div>
    );
};

export default StepItem;
