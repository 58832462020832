import axios from "../utils/request";
import qs from "qs";
import token from "@/utils/token";

const addToken = (params) => {
    if (!params) params = {};
    let domain = window.location.host;
    if (domain.indexOf("localhost") !== -1) {
        domain = "testfsd.maidelong.com";
    }
    params.domain = domain;
    let tokenVal = token.getToken();
    if (tokenVal) {
        return qs.stringify({
            request: JSON.stringify(params),
            token: tokenVal,
        });
    }

    return qs.stringify({ request: JSON.stringify(params) });
};

function post(url, params, config = {}) {
    let _params = addToken(params);
    return axios.post(url, _params, config);
}

function download(url, params) {
    let _params = addToken(params);
    return axios({
        method: "post",
        url: url,
        data: _params,
        responseType: "blob",
    });
    // return axios.post(url, _params, config);
}

function upload(url, params) {
    const data = new FormData();
    if (params.file) {
        data.append("file", params.file);
    }
    if (params.request) {
        data.append("request", JSON.stringify(params.request));
    }
    let tokenVal = token.getToken();
    data.append("token", tokenVal);
    return axios.post(url, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

const configApi = {
    fetchHome: (config) => {
        return post("/homePage/query", {}, config);
    },
    // 常购商品推荐
    fetchOftenBuy: (config) => {
        return post("/bigData/oftenBuy", {}, config);
    },
    // 大数据推荐
    fetchRecommend: (params, config) => {
        return post("/bigData/recommend", params, config);
    },
    // 楼层商品
    fetchFloorGoods: (params, config) => {
        return post("/homePage/floor", params, config);
    },
    // 活动信息
    fetchActivity: (params, config) => {
        return post("/activity/query", params, { ...config, noTips: true });
    },
    // 活动分层商品信息
    fetchActivityDetail: (params, config) => {
        return post("/activity/queryDetail", params, {
            ...config,
            noTips: true,
        });
    },
    // 校验是否是外部接入
    checkKA: (params, config) => {
        return post("/customer/checkKa", params, {
            ...config,
            noTips: true,
        });
    },
};

const loginApi = {
    kaLogin: (params, config) => {
        return post("/user/kalogin", params, {
            ...config,
            errAll: true,
            noTips: true,
        });
    },
    doLogin: (params, config) => {
        return post("/user/login", params, { ...config, noTips: true });
    },
    sendSms: (params) => {
        return post("/user/sendSms", params, { noTips: true });
    },
    regist: (params) => {
        return post("/customer/userTrack", params, { noTips: true });
    },
    fetchAreaList: () => {
        return axios.get("/user/areaList");
    },
};

const userApi = {
    fetchCards: (config) => {
        return post("/customer/cards", {}, config);
    },
    changeCard: (params, config) => {
        return post("/customer/changeCard", params, config);
    },
    changeAddr: (params) => {
        return post("/customer/changeAddress", params);
    },
    fetchUserInfo: (config) => {
        return post("/customer/getUserInfoByToken", {}, config);
    },
    fetchTitleUserInfo: (config) => {
        return post("/customer/titleUserInfo", {}, config);
    },
    fetchCredit: () => {
        return post("/customer/getCompanyCreditInfoByECustomerCode", {});
    },
};

const cardApi = {
    fetchChoosedCards: () => {
        return post("/customer/getChoosedCards");
    },
    removeCard: (params) => {
        return post("/customer/removeCard", params);
    },
    addCard: (params) => {
        return post("/customer/addCard", params);
    },
};

const goodsApi = {
    fetchAllCategory: (config) => {
        return post("/goods/category/preTree/all", {}, config);
    },
    fetchGoodsByWords: (params, config) => {
        return post("/goods/store/search2", params, config);
    },
    fetchGoodsByWordsOnCart: (params) => {
        return post("/goods/store/search", params);
    },
    fetchGoodsByCategory: (params) => {
        return post("/goods/list/category", params);
    },
    fetchGoodsInfo: (params, config) => {
        return post("/goods/detail/rfId", params, config);
    },
    fetchParentCate: (params) => {
        return post("/goods/category/parentTree", params);
    },
    fetchCategoryList: (params) => {
        return post("/goods/category/list", params);
    },
    fetchBrandList: (params) => {
        return post("/goods/store/brandList", params);
    }
};

const orderApi = {
    getOrderList: (params) => {
        return post("/order/orderList", params);
    },
    getOrderDetail: (params) => {
        return post("/order/orderDetail", params);
    },
    oneMoreOrder: (params) => {
        return post("/order/orderToShoppingCart", params);
    },
    getOrderCountTime: (params) => {
        return axios.get("/order/b/orderCount", params);
    },
    getDeliveryTime: (params) => {
        return axios.get("/Order/getOrderDeliveryTime", params);
    },
    editOrderStatus: (params) => {
        return post("/order/orderStatus", params);
    },
    cancelOrder: (params) => {
        return post("/order/cancel", params);
    },
    orderTime: (params) => {
        return post("/order/orderTimeOutTime", params);
    },
    deleteOrderById: (params) => {
        return post("/order/b/deleteOrderGoodsById", params);
    },
    saveOrder: (params) => {
        return post("/order/b/saveOrder", params);
    },
    paymentList: () => {
        return post("/order/paymentlist", {});
    },
    submitOrder: (params) => {
        return post("/order/b/submitOrder", params);
    },
    ackOrder: (params) => {
        return post("/order/b/ackOrder", params);
    },
    updateOrder: (params) => {
        return post("/order/b/updateOrder", params);
    },
    // getGoodsByKeyWord: (params) => {
    //     return post("/order/b/getGoodsByKeyWord", params);
    // },
    updatePrice: (params) => {
        return post("/order/b/updatePrice", params);
    },
    checkingStockByDate: (params) => {
        return post("/order/checkingStockByDate", params);
    },
    checkOut: (params) => {
        return post("/order/checkOut", params);
    },
    checkOutGoods: (params) => {
        return axios.get("/order/checkOutGoods", params);
    },
    checkOutTitle: (params) => {
        return post("/order/checkOutTitle", params);
    },
    orderGoodsList: (params) => {
        return post("/order/orderGoodsList", params);
    },
    findOrderList: (params) => {
        return post("/order/orderList", params);
    },
    orderInfoList: (params) => {
        return post("/order/queryOrderTrackingInfolist", params);
    },
    getOrderExcel: (params) => {
        return post("order/getOrderExecl", params);
    },
    sendEmailWithOrder: (params) => {
        return post("/order/sendMail", params);
    },
    sendEmailWithOrderList: (params) => {
        return post("/order/orderList/sendMail", params);
    },
    downloadExcel: (params) => {
        return post("/order/orderList/Execl", params);
    },
    orderBillType: (params) => {
        return post("/order/billType", params);
    },
};

const approveApi = {
    getApproveList: (params) => {
        return post("/order/audit", params);
    },
};

const shopCartApi = {
    getShopCartList: (params) => {
        return post("/shopping/cart/page", params);
    },
    queryGoodPrices: (params) => {
        return post("/shopping/cart/list/sum", params);
    },
    editShopCartNum: (params) => {
        return post("/shopping/cart/update/qty", params, { errAll: true });
    },
    removeGoodFromCart: (params) => {
        return post("/shopping/cart/remove", params);
    },
    addGoodToCartRemark: (params) => {
        return post("/shopping/cart/add/remark", params);
    },
    addGoodToCart: (params) => {
        return post("/shopping/cart/add", params);
    },
    importGoodToCart: (params) => {
        return upload("/shopping/cart/import", params);
    },
    updateSellType: (params) => {
        return post("/shopping/cart/updateSellPackage", params);
    },
    updateCuttingMethod: (params) => {
        return post("/shopping/cart/updateServiceOption", params);
    },
    updateSellAndCutting: (params) => {
        return post("/shopping/cart/updateRateAndOption", params);
    },
    exportAddLog: (params) => {
        return download("/shopping/cart/log/export", params);
    },
};

// 售后接口
const afterSaleApi = {
    getAfterSaleList: (params) => {
        return post("/order/getRefundableList", params);
    },
    applyAfterSale: (params) => {
        return post("/order/addRefundableInfo", params);
    },
    getRefundableInfo: (params) => {
        return post("/order/getRefundableInfo", params);
    },
    getRefundableDetail: (params) => {
        return post("/order/getRefundableDetailList", params);
    },
    getAfterSaleTrackingInfoList: (params) => {
        return post("/order/queryTrackingInfoList", params);
    },
};

/**
 * 收藏夹相关API
 */
const COLLECT_API = {
    /**
     * 查询收藏夹列表
     */
    getCollectList: (params, config) => {
        return post("/favorite/findList", params, config);
    },
    /**
     * 新增收藏夹
     */
    addCollect: (params) => {
        return post("/favorite/insert", params);
    },
    /**
     * 删除收藏夹
     */
    deleteCollect: (params) => {
        return post("/favorite/delete", params);
    },
    /**
     * 查询收藏夹详情商品
     */
    getCollectDetail: (params) => {
        return post("/favorite/goodsPage", params);
    },
    /**
     * 删除收藏夹详情商品
     */
    deleteCollectDetail: (params) => {
        return post("/favorite/deleteGoods", params);
    },
    /**
     *转移商品到另外一个收藏夹
     */
    moveGoodsToCollect: (params) => {
        return post("/favorite/goodsConvert", params);
    },
    /**
     * 批量加入购物车（批量）
     */
    addShoppingCart: (params) => {
        return post("/shopping/cart/batchAdd", params);
    },
    /**
     * 添加购物车（收藏夹）
     */
    addShoppingCartByFavorite: (params) => {
        return post("/shopping/cart/favoriteAdd", params);
    },
    /**
     * 更新收藏夹
     */
    updateCollect: (params) => {
        return post("/favorite/update", params);
    },
};

const statisticApi = {
    // 收藏、订单、购物车数据统计
    getFavOrderCartCount: () => {
        return post("/statistics/getFavOrderCartCount");
    },
};

const msgApi = {
    getMessageList: (params, config) => {
        return post("/message/getMessagePage", params, config);
    },
    getMessageCount: () => {
        return post("/message/getMessageCount");
    },
};

const suggestApi = {
    addSuggest: (params) => {
        return upload("suggest/insert", params);
    },
};

export {
    configApi,
    userApi,
    goodsApi,
    cardApi,
    loginApi,
    orderApi,
    approveApi,
    shopCartApi,
    COLLECT_API,
    statisticApi,
    msgApi,
    suggestApi,
    afterSaleApi,
};
