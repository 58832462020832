import React, { cloneElement } from "react";
import toArray from "rc-util/lib/Children/toArray";
import "./index.scss";

const Steps = (props) => {
    // current : default 0;
    const {
        className,
        style,
        current,
        status,
        children,
        size,
        bottom = false,
        ...restProps
    } = props;
    console.log("children", children, className);
    return (
        <div
            className={(className, "stepsContainer")}
            style={style}
            {...restProps}
        >
            {toArray(children).map((child, index) => {
                const stepNumber = index;
                const childProps = {
                    done: current >= index,
                    stepNumber: `${stepNumber + 1}`,
                    stepIndex: stepNumber,
                    key: stepNumber,
                    bottom: bottom,
                    size: size,
                    current: current,
                    isLast: children.length - 1 == index,
                    ...child.props,
                };

                if (!child.props.status) {
                    if (stepNumber === current) {
                        childProps.status = status;
                    } else if (stepNumber < current) {
                        childProps.status = "finish";
                    } else {
                        childProps.status = "wait";
                    }
                }

                childProps.active = stepNumber === current;
                return cloneElement(child, childProps);
            })}
        </div>
    );
};

export default Steps;
