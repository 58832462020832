import asyncComponent from "./lazy";
const Dashboard = asyncComponent(() => import("@/page/dashboard/dashboard"));
const Order = asyncComponent(() => import("@/page/order/list"));
const Cart = asyncComponent(() => import("@/page/cart/cart"));
const CartInfo = asyncComponent(() => import("@/page/cart/cartInfo"));
const CartResult = asyncComponent(() => import("@/page/cart/cartResult"));
const OrderDetail = asyncComponent(() => import("@/page/order/detail"));
const AfterSaleCreate = asyncComponent(() =>
    import("@/page/afterSale/afterSaleCreate")
);
const AfterSaleDetail = asyncComponent(() =>
    import("@/page/afterSale/afterSaleDetail")
);
const AfterSaleAllList = asyncComponent(() =>
    import("@/page/afterSale/afterSaleAllList")
);
const Approve = asyncComponent(() => import("@/page/approve/approve"));
const ApproveDetail = asyncComponent(() =>
    import("@/page/approve/approveDetail")
);

const GoodsList = asyncComponent(() => import("@/page/goods/list/list"));
const GoodsDetail = asyncComponent(() => import("@/page/goods/detail/detail"));
const Member = asyncComponent(() => import("@/page/member/member"));
const Message = asyncComponent(() => import("@/page/message/message"));
const Collect = asyncComponent(() => import("@/page/collect"));
const CollectDetail = asyncComponent(() =>
    import("@/page/collect/detail/index")
);
const SwitchCards = asyncComponent(() => import("@/page/switchCards/index"));
const Doc = asyncComponent(() => import("@/page/document/index"));
const NotFound = asyncComponent(() => import("@/page/notFound/index"));
const Activity = asyncComponent(() => import("@/page/activity/index"));
const Login = asyncComponent(() => import("@/page/login/login"));
const SysErr = asyncComponent(() => import("@/page/sysErr/index"));

const routes = [
    {
        path: "/",
        component: Dashboard,
        exact: true,
        meta: {
            title: "麦德龙-首页",
        },
    },
    {
        path: "/login",
        component: Login,
        exact: true,
        meta: {
            title: "麦德龙-登录",
        },
    },
    {
        path: "/switchCards",
        component: SwitchCards,
        exact: true,
        meta: {
            title: "麦德龙-选择会员卡",
            userSys: true
        },
    },
    {
        path: "/order",
        component: Order,
        exact: true,
        meta: {
            title: "麦德龙-订单列表",
        },
    },
    {
        path: "/cart",
        component: Cart,
        exact: true,
        meta: {
            title: "麦德龙-购物车",
        },
    },
    {
        path: "/cartInfo",
        component: CartInfo,
        exact: true,
        meta: {
            title: "提交订单",
        },
    },
    {
        path: "/cartResult",
        component: CartResult,
        exact: true,
        meta: {
            title: "提交结果",
        },
    },
    {
        path: "/detail/:data",
        component: OrderDetail,
        exact: true,
        meta: {
            title: "麦德龙-订单详情",
        },
    },
    {
        path: "/aftersaleCreate/:data",
        component: AfterSaleCreate,
        exact: true,
        meta: {
            title: "麦德龙-新建售后",
            userSys: true
        },
    },
    {
        path: "/aftersale/detail/:data",
        component: AfterSaleDetail,
        exact: true,
        meta: {
            title: "麦德龙-售后详情",
            userSys: true
        },
    },
    {
        path: "/aftersale/list",
        component: AfterSaleAllList,
        exact: true,
        meta: {
            title: "麦德龙-售后列表",
            userSys: true
        },
    },
    {
        path: "/approve",
        component: Approve,
        exact: true,
        meta: {
            title: "麦德龙-审核列表",
            userSys: true
        },
    },
    {
        path: "/approve/detail/:id",
        component: ApproveDetail,
        exact: true,
        meta: {
            title: "麦德龙-审核详情",
            userSys: true
        },
    },
    {
        path: "/goodsList/:keywords?/:type?/:time?",
        component: GoodsList,
        exact: true,
        meta: {
            title: "麦德龙-商品列表",
        },
    },
    {
        path: "/goodsDetail/:rfId",
        component: GoodsDetail,
        exact: true,
        meta: {
            title: "麦德龙-商品详情",
        },
    },
    {
        path: "/member",
        component: Member,
        exact: true,
        meta: {
            title: "麦德龙-会员中心",
            userSys: true
        },
    },
    {
        path: "/message",
        component: Message,
        exact: true,
        meta: {
            title: "麦德龙-消息列表",
        },
    },
    {
        path: "/collect",
        component: Collect,
        exact: true,
        meta: {
            title: "麦德龙-我的收藏",
        },
    },
    {
        path: "/collect/detail/:id/:type",
        component: CollectDetail,
        exact: true,
        meta: {
            title: "麦德龙-我的收藏",
        },
    },
    {
        path: "/activity/:id",
        component: Activity,
        exact: true,
        meta: {
            title: "麦德龙-活动",
        },
    },
    {
        path: "/doc/:type",
        component: Doc,
        exact: true,
        meta: {
            title: "麦德龙",
        },
    },
    {
        path: "/404",
        component: NotFound,
        exact: true,
        meta: {
            title: "麦德龙-404",
        },
    },
    {
        path: "/sysErr",
        component: SysErr,
        exact: true,
        meta: {
            title: "麦德龙-系统维护",
        },
    },
];

export default routes;
