import React from "react";
import Loading from "@/page/loading/loading";
import { showAgreement, showReEntry } from "@/components";
import { loginApi, configApi } from "@/api/api";
import token from "../utils/token";
import { Tools } from '@/utils';

const asyncComponent = (importComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null,
            };
        }

        componentDidMount() {
            const queryParamStr = this.props.location.search.substring(1);
            const params = Tools.urlParam(queryParamStr);

            if (this.props.location.pathname === "/" && params.sign) {
                localStorage.setItem('outApp', 1);
                this.outLogin(params.sign);
            } else {
                if (token.getToken()) {
                    configApi.checkKA().then((res) => {
                        // 校验是否是外部应用标志
                        if (res === 1) {
                            localStorage.setItem('outApp', 1);
                        } else {
                            localStorage.removeItem('outApp');
                        }
                    }).finally(() => {
                        this.loadComponent();
                    });
                } else {
                    localStorage.removeItem('outApp');
                    this.loadComponent();
                }
            }
        }

        loadComponent = () => {
            importComponent().then((cmp) => {
                this.setState({ component: cmp.default });
            });
        }

        outLogin = (sign) => {
            loginApi.kaLogin({ sign }).then((res) => {
                token.setToken(res);
                window.location.href = window.location.origin;
            }).catch(e => {
                if (e) {
                    if (e.code === '2031039') {
                        showAgreement.show({ sign: sign, callBack: this.loadComponent });
                    } else if (!showReEntry.getFlag()) {
                        showReEntry.show({ msg: e.message || '' });
                    }
                }
            });
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props} /> : <Loading></Loading>;
        }
    };
};

export default asyncComponent;
