import React, { useEffect, useState } from 'react';
import { showAgreement, showReEntry } from '@/components';
import { configApi, loginApi } from '@/api/api';
import token from '@/utils/token';
import './agreementModal.scss';

const AgreementModal = (props) => {
    const [countdown, setCountdown] = useState(5);
    const [agreementPaper, setAgreementPaper] = useState('');
    let timer = null;

    const confirm = () => {
        if (countdown > 0) return;
        loginApi.kaLogin({ sign: props.sign, isSelectAgreement: 1 }).then((res) => {
            token.setToken(res);
            showAgreement.hide();
            window.location.href = window.location.origin;
            if (props.callBack) props.callBack();
        }).catch(e => {
            if (!showReEntry.getFlag()) {
                showReEntry.show({ msg: e.message });
            }
        });
    }

    const initAgreement = () => {
        const params = {
            id: 0,
            index: 7
        };
        configApi.fetchActivity(params).then(res => {
            if (res && res.paper) setAgreementPaper(res.paper);
        });
    }

    useEffect(() => {
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
        initAgreement();
    }, []);

    return (
        <div className="agreement-modal">
            <div className="agreement-body">
                <div className="header-box">麦德龙客户线上平台交易协议</div>
                <div className="content-box">
                    <div dangerouslySetInnerHTML={{ __html: agreementPaper }}></div>
                </div>
                <div className="btn-box">
                    <div className="confirm-btn" onClick={confirm}>
                        我已阅读并同意协议 {countdown > 0 ? `(${countdown}s)` : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgreementModal;