const token = {
    getToken: () => {
        return localStorage.getItem('token');
    },
    setToken: (tokenVal) => {
        localStorage.setItem('token', tokenVal);
    },
    removeToken: () => {
        localStorage.removeItem('token');
    },
    getLoginPhone: () => {
        return localStorage.getItem('phone');
    },
    setLoginPhone: (val) => {
        localStorage.setItem('phone', val);
    },
    removeLoginPhone: () => {
        localStorage.removeItem('phone');
    }
};

export default token;