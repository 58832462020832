import { combineReducers } from 'redux';
import addrReducer from './addrReducer';
import userInfoReducer from './userInfoReducer';
import msgReducer from './msgReducer';
import statisticReducer from './statisticReducer';

const rootReducer = combineReducers({
    addrReducer: addrReducer,
    userInfo: userInfoReducer,
    msgCount: msgReducer,
    statistic: statisticReducer,
})

export default rootReducer