import React, { useState } from "react";
import "./oneOrderList.scss";
import StatusOrder from "./statusOrder.jsx";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { orderApi, shopCartApi } from "../../../api/api";
import { Images } from "../../../assets/index";
import { Modal } from "antd";
import throttle from "lodash/throttle";
import { useDispatch } from "react-redux";
import { fetchStatistic } from "@/redux/actions";

function OneOrderList(props) {
    const outApp = localStorage.getItem("outApp");
    const dispatch = useDispatch();
    const maskStyle = {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    };
    const history = useHistory();
    const [showAll, setShowAll] = useState(false);
    const [oneMoreModelShow, setOneMoreModelShow] = useState(false);
    const [successCartGoodsList, setSuccessList] = useState([]);
    const [errorCartGoodsList, setErrorList] = useState([]);

    function jumpDetail() {
        let data = JSON.stringify({
            id: props.info.id,
            orderId: props.info.orderId,
        });
        history.push("/detail/" + data);
    }

    function stateForm() {
        // props.info.orderStateDesByPc;
        if (props.info.orderStateDesByPc == "交易成功") {
            const {orderStateDesByPc, receiptStatusDes} = props.info;
            if (receiptStatusDes) {
                return `${orderStateDesByPc}，${receiptStatusDes}`
            }
            return orderStateDesByPc;
        }
        return props.info.orderStateDesByPc;
    }

    function jumpAfterSaleCreate() {
        let data = JSON.stringify({
            orderId: props.info.id,
            groupOrderId: props.info.groupOrderId,
        });
        history.push("/aftersaleCreate/" + data);
    }

    function checkSignPhoto() {
        const { imgs } = props.info.receiptDto;
        if (imgs && imgs.length > 0) {
            imgs.forEach((item) => {
                window.open(item, "_blank");
            })
        }
    }

    function formatTime(date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
    }

    function addOneMoreOrder() {
        orderApi
            .oneMoreOrder({
                orderId: props.info.id,
            })
            .then((res) => {
                if (res) {
                    const { successCartGoodsList, errorCartGoodsList } = res;
                    setSuccessList(successCartGoodsList || []);
                    setErrorList(errorCartGoodsList || []);
                    setOneMoreModelShow(true);
                    dispatch(fetchStatistic());
                }
            })
            .catch((err) => {
                console.log("onMoreOrder err", err);
            });
    }

    function addMore() {
        const addMoreDelay = throttle(() => {
            addOneMoreOrder();
        }, 500);
        addMoreDelay();
    }

    return (
        <div>
            <div className="oneOrderListContent">
                <div className="innerOrderListContent">
                    <div className="oneOrderListHeader">
                        {props.info.orderId ? (
                            <div className="orderNumView">
                                <div className="orderNumTitle">
                                    订单编号：{props.info.orderId}
                                </div>
                            </div>
                        ) : null}
                        <div className="orderTime">
                            下单时间：{formatTime(props.info.created)}
                        </div>
                        <div className="orderSource">
                            {props.channelTypeDesc}
                        </div>
                    </div>
                </div>
                <div className="orderList">
                    <div>
                        {props.detailList.map((item, index) => {
                            if (!showAll && index > 2) return null;
                            return (
                                <StatusOrder
                                    key={index}
                                    orderItem={item}
                                ></StatusOrder>
                            );
                        })}
                    </div>

                    <div className="orderItemView orderStatusView">
                        <div
                            className={
                                props.info.status === 20 ||
                                props.info.status === 30 ||
                                props.info.groupStatus == 32
                                    ? "statusRed"
                                    : props.info.status == 40 || props.info.orderStateDesByPc == "交易成功"
                                    ? "statusBlue"
                                    : "statusGray"
                            }
                        >
                            {stateForm()}
                        </div>
                        <a
                            className="orderText orderDetailText"
                            onClick={jumpDetail}
                        >
                            订单详情
                        </a>
                    </div>
                    <div className="orderItemView orderOperation">
                        <a className="orderText" onClick={addMore}>
                            再来一单
                        </a>
                        {props.info.orderStateDesByPc == "交易成功"&&
                            !outApp && (
                                <a
                                    className="orderText"
                                    onClick={jumpAfterSaleCreate}
                                >
                                    售后
                                </a>
                            )}
                        {props.info.receiptDto &&
                            props.info.receiptDto.receiptStatus == 1 &&
                            props.info.receiptDto.imgs && (
                                <a
                                    className="orderText"
                                    onClick={checkSignPhoto}
                                >
                                    签收快照
                                </a>
                            )}
                        {/* <a className="orderAfterSale">售后</a> */}
                    </div>
                </div>
            </div>
            {props.detailList.length <= 3 ? null : (
                <div className="moreItemView">
                    {showAll ? (
                        <div className="moreView">
                            <a
                                className="moreText moreButtonTextHighColor"
                                onClick={() => {
                                    setShowAll(!showAll);
                                }}
                            >
                                <span>收起</span>
                                <img src={Images.listUpArrow} />
                            </a>
                        </div>
                    ) : (
                        <div className="moreView">
                            <div className="moreText">共</div>
                            <div className="moreText moreTextHighColor">
                                {props.detailList.length}
                            </div>
                            <div className="moreText">种商品，</div>
                            <a
                                className="moreText moreButtonTextHighColor"
                                onClick={() => {
                                    setShowAll(!showAll);
                                }}
                            >
                                <span>点击查看</span>
                                <img src={Images.listDownArrow} />
                            </a>
                        </div>
                    )}
                </div>
            )}
            <Modal
                footer={null}
                maskStyle={maskStyle}
                onCancel={() => {
                    setOneMoreModelShow(false);
                }}
                title="再来一单"
                visible={oneMoreModelShow}
                width={408}
                wrapClassName="cartModel"
            >
                <div className="addTipView">
                    {/* <img src={Images.greenCorrectPic} /> */}
                    <span>
                        共
                        {successCartGoodsList.length +
                            errorCartGoodsList.length}
                        件商品加入购物车，成功{successCartGoodsList.length}
                        条，失败
                        <span style={{ color: "#FE3B30" }}>
                            {errorCartGoodsList.length}
                        </span>
                        条。
                    </span>
                </div>
                <div className="cartModelButton">
                    <button
                        className="cancelBtn"
                        onClick={() => {
                            setOneMoreModelShow(false);
                        }}
                    >
                        取消
                    </button>
                    <button
                        className="submitBtn"
                        onClick={() => {
                            // setOneMoreModelShow(false);
                            // 去结算页
                            // history.push("/cart");
                            shopCartApi.exportAddLog().then((res) => {
                                const url = window.URL.createObjectURL(
                                    new Blob([res])
                                );
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                    "download",
                                    "再来一单加购物车结果.xlsx"
                                );
                                document.body.appendChild(link);
                                link.click();
                            });
                        }}
                    >
                        导出明细
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default OneOrderList;
