import React, { useEffect, useState } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { Images } from '@/assets';

const FloatNavBar = () => {
    const [leftW, setLeftW] = useState(0);
    const history = useHistory();

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const goToCollect = () => {
        history.push('/collect')
    }

    const goToCart = () => {
        history.push('/cart')
    }

    useEffect(() => {
        let clientW = document.documentElement.clientWidth;
        let left = 0;
        if (clientW > 1226 + 140) {
            left = clientW - (clientW - 1226) / 2 + 10;
        } else {
            left = clientW - 70;
        }
        setLeftW(left);
    }, []);

    return (
        <div className="navigate-bar-block" style={{ left: leftW }}>
            <div className="nav-item collect-item" onClick={goToCollect}>
                <img className="img-light" src={Images.lightCollectPic} />
                <img className="img-grey" src={Images.greyCollectPic} />
                <span className="text-item">收藏</span>
            </div>
            <div className="line"></div>
            <div className="nav-item cart-item" onClick={goToCart}>
                <img className="img-light" src={Images.lightCartPic} />
                <img className="img-grey" src={Images.greyCartPic} />
                <span className="text-item">购物车</span>
            </div>
            <div className="line"></div>
            <div className="nav-item top-item" onClick={goToTop}>
                <img className="img-light" src={Images.lightTopPic} />
                <img className="img-grey" src={Images.greyTopPic} />
                <span className="text-item">顶部</span>
            </div>
        </div>
    );
}

export default FloatNavBar;