import React from "react";
import { useHistory } from "react-router-dom";
import { Images } from "../../../assets/index";
import "./orderEmptyView.scss";

function orderEmptyView(props) {
    const history = useHistory();

    const { showButton = true } = props;

    return (
        <div className="orderEmptyView">
            <div className="orderEmptyImgView">
                <img src={Images.orderEmpty} />
            </div>
            <div className="orderEmptyInfo">
                <span className="orderEmptyInfoTitle">{`没有${
                    props.title || "交易关闭"
                }的订单哦~`}</span>
                {showButton && (
                    <a
                        className="orderEmptyButton"
                        onClick={() => {
                            history.push("/");
                        }}
                    >
                        <span className="orderEmptyButtonText">去首页看看</span>
                    </a>
                )}
            </div>
        </div>
    );
}

export default orderEmptyView;
