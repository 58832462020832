import React from "react";
import moment from "moment";
import "./detailOrderInfo.scss";

function DetailOrderInfo(props) {
    const { approveType = false } = props;

    function remarkText(remark) {
        let ret = "";
        try {
            let obj = JSON.parse(remark);

            if (obj["orderRemark"] && obj["orderRemark"].length != 0) {
                ret += obj["orderRemark"] + ";";
            }
            if (obj["deliverRemark"] && obj["deliverRemark"].length != 0) {
                ret += obj["deliverRemark"] + ";";
            }
            if (obj["invoiceRemark"] && obj["invoiceRemark"].length != 0) {
                ret += obj["invoiceRemark"] + ";";
            }
        } catch (error) {
            // console.log(error);
        }
        return ret;
    }

    return (
        <div className="detailOrderInfoContent">
            <div className="infoHeader">
                <span>订单信息</span>
            </div>
            <div className="infoContent">
                <div className="orderInfoView">
                    {approveType ? (
                        <div className="orderInfoItem">
                            <span className="orderHeadText">订单编号：</span>
                            <span className="orderInfoText">
                                {props.orderId}
                            </span>
                        </div>
                    ) : (
                        <>
                            {props.orderId && (
                                <div className="orderInfoItem">
                                    <span className="orderHeadText">
                                        订单编号：
                                    </span>
                                    <span className="orderInfoText">
                                        {props.orderId}
                                    </span>
                                </div>
                            )}

                            <div className="orderInfoItem">
                                <span className="orderHeadText">
                                    下单时间：
                                </span>
                                <span className="orderInfoText">
                                    {moment(props.preOrderDto.created).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )}
                                </span>
                            </div>
                        </>
                    )}
                    {approveType ? (
                        <div className="orderInfoItem">
                            <span className="orderHeadText">下单时间：</span>
                            <span className="orderInfoText">
                                {moment(props.preOrderDto.created).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )}
                            </span>
                        </div>
                    ) : (
                        <div className="orderInfoItem">
                            <span className="orderHeadText">收 货 人 ：</span>
                            <span className="orderInfoText">
                                {props.preOrderDto.receiveName}{" "}
                                {props.preOrderDto.receivePhone}
                            </span>
                        </div>
                    )}

                    {approveType && (
                        <div className="orderInfoItem">
                            <span className="orderHeadText">收 货 人 ：</span>
                            <span className="orderInfoText">
                                {props.preOrderDto.receiveName}{" "}
                                {props.preOrderDto.receivePhone}
                            </span>
                        </div>
                    )}

                    <div className="orderInfoItem">
                        <div className="orderHeadText">特殊要求：</div>
                        <div className="orderInfoText remarkText">
                            {remarkText(props.preOrderDto.remark) || "-"}
                        </div>
                    </div>
                </div>
                <div className="orderInfoView orderNextInfoView">
                    <div className="orderInfoItem">
                        <span className="orderHeadText">支付类型：</span>
                        <span className="orderInfoText">
                            {props.preOrderDto.paymentTypeName}
                        </span>
                    </div>
                    <div className="orderInfoItem">
                        <span className="orderHeadText">预计配送：</span>
                        <span className="orderInfoText">
                            {moment(props.preOrderDto.deliveryDate).format(
                                "YYYY-MM-DD"
                            )}
                        </span>
                    </div>
                    <div className="orderInfoItem">
                        <div className="orderHeadText">收货地址：</div>
                        <div className="addressText">
                            {props.preOrderDto.addressName}
                        </div>
                    </div>
                    {props.preOrderDto.billType == 1 ||
                    props.preOrderDto.billType == 2 ? (
                        <div className="orderInfoItem">
                            <div className="orderHeadText">销货清单类型：</div>
                            <div className="addressText">
                                {props.preOrderDto.billType == 1
                                    ? "普通销货清单"
                                    : "增值税销货清单"}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default DetailOrderInfo;
