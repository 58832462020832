import React from 'react';
import moment from 'moment';
import './index.scss';

const CardBlock = (props) => {
    const { active = false, index = 0, item = {}, chooseCard = () => { } } = props;
    return (
        <div
            className={active ? "card-item active" : "card-item"}
            key={index}
            onClick={() => chooseCard()}
        >
            <div className="card-info-item">
                <span className="label-item">
                    持卡姓名
                </span>
                <span className="value-item">
                    {item.name || '--'}
                </span>
            </div>
            <div className="card-info-item">
                <span className="label-item">
                    会员卡号
                </span>
                <span className="value-item">
                    {item.memberNo || '--'}
                </span>
            </div>
            <div className="card-info-item">
                <span className="label-item">
                    单位名称
                </span>
                <span className="value-item">
                    {item.companyName || '--'}
                </span>
            </div>
            <div className="card-info-item">
                <span className="label-item">
                    持卡时间
                </span>
                <span className="value-item">
                    {item.created ? moment(item.created).format('YYYY_MM_DD HH:mm:ss') : '--'}
                </span>
            </div>
        </div>
    );
}

export default CardBlock;