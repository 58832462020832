/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Select } from "antd";
import { loginApi, userApi } from "@/api/api";
import token from "@/utils/token";
import "./loginModel.scss";
import { withRouter } from "react-router-dom";
import { switchCard, CodeModal } from '@/components';
import { Images } from '@/assets';
import { generateCancelToken } from '@/utils/index';

const { Option } = Select;

class LoginModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginModdelTab: props.type === 1 ? 1 : 0,
            agreement: 1,
            codeVisible: false,
            loginForm: {
                phone: "",
                msgCode: "",
                err: "",
            },
            registerForm: {
                phone: "",
                msgCode: "",
                name: "",
                company: "",
                province: "",
                city: "",
                distinct: "",
                err: "请准确输入信息，销售经理将尽快通过您填写的信息联系您",
            },
            loginTimerCount: 60,
            loginIfSendMsg: false,
            registTimerCount: 60,
            registIfSendMsg: false,
            areaList: {},
            provinceList: [],
            cityList: [],
            distinctList: [],
        };

        this.removeModel = this.removeModel.bind(this);
        this.doLogin = this.doLogin.bind(this);
        // 初始化生成cancelToken实例和cancel方法
        const { cancel, cancelToken } = generateCancelToken();
        this.cancelToken = cancelToken;
        this.cancel = cancel;
    }

    componentDidMount() {
        const { isView } = this.props;
        const isLogin = !!token.getToken(); // 是否已登录
        if (!isView && isLogin) {
            this.fetchUserInfo();
        }
        // this.initAreaList();
    }

    componentWillUnmount() {
        if (this.cancel) this.cancel();
    }

    removeModel() {
        this.props.closeFun();
    }

    // initAreaList = () => {
    //     loginApi.fetchAreaList().then(res => {
    //         if (res) {
    //             this.setState({
    //                 areaList: res,
    //                 provinceList: res.province,
    //             })
    //         }
    //     })
    // }

    setErr = (err) => {
        const { loginModdelTab, loginForm, registerForm } = this.state;
        if (loginModdelTab === 0) {
            const loginData = JSON.parse(JSON.stringify(loginForm));
            loginData.err = err;
            this.setState({
                loginForm: loginData,
            });
        } else if (loginModdelTab === 1) {
            const registData = JSON.parse(JSON.stringify(registerForm));
            registData.err = err;
            this.setState({
                registerForm: registData,
            });
        }
    };

    fetchUserInfo = () => {
        const _this = this;
        userApi.fetchUserInfo({ cancelToken: this.cancelToken }).then(res => {
            if (!res.chooseCardUserIds) {
                switchCard.show(_this.props);
            } else {
                const { isView } = _this.props;
                if (isView) {
                    // 登陆成功之后的跳转
                    let redirectUrl = '/';
                    if (_this.props.location.state) {
                        const pathName = _this.props.location.state.from.pathname;
                        redirectUrl = (pathName === '/login' ? '/' : pathName);
                    }
                    _this.props.history.push(redirectUrl);
                } else {
                    window.location.reload();
                }
            }
        }).catch((e) => {
            if (!e.cancel && window.location.href.indexOf('login') > -1) {
                switchCard.show(_this.props);
            }
        });
    }

    doLogin() {
        const { loginForm, agreement } = this.state;
        // const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        const reg = /^[1][0-9]{10}$/;
        if (!reg.test(loginForm.phone)) {
            this.setErr("请输入正确的手机号");
            return;
        }
        if (!loginForm.msgCode) {
            this.setErr("请输入手机验证码");
            return;
        }
        if (agreement === 1) {
            this.setErr("请阅读并同意麦鲜达隐私协议");
            return;
        }

        this.setErr("");
        const params = {
            mobile: loginForm.phone,
            agreement: agreement,
            code: loginForm.msgCode,
        };
        loginApi
            .doLogin(params, { cancelToken: this.cancelToken })
            .then((res) => {
                token.setToken(res);
                token.setLoginPhone(loginForm.phone);
                this.fetchUserInfo();
            })
            .catch((err) => {
                if (!err.cancel) this.setErr(err);
            });
    }

    getCurrentPhone() {
        const { loginModdelTab, registerForm, loginForm } = this.state;
        let phone = '';
        if (loginModdelTab === 0) {
            phone = loginForm.phone;
        }
        if (loginModdelTab === 1) {
            phone = registerForm.phone;
        }
        return phone;
    }

    showCodeModal() {
        const {
            loginModdelTab,
            registIfSendMsg,
            loginIfSendMsg,
            loginForm,
            registerForm,
        } = this.state;

        // const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        const reg = /^[1][0-9]{10}$/;
        if (loginModdelTab === 0 && !reg.test(loginForm.phone)) {
            this.setErr("请输入正确的手机号");
            return;
        } else if (loginModdelTab === 1 && !reg.test(registerForm.phone)) {
            this.setErr("请输入正确的手机号");
            return;
        }

        if (
            (loginModdelTab === 1 && !registIfSendMsg) ||
            (loginModdelTab === 0 && !loginIfSendMsg)
        ) {
            this.setState({
                codeVisible: true,
            });
        }
    }

    hideCodeModal() {
        this.setState({
            codeVisible: false,
        });
    }

    checkAgreement(e) {
        this.setState({
            agreement: e.target.checked ? 2 : 1,
        });
    }

    handleLoginChange(e, key) {
        const loginData = JSON.parse(JSON.stringify(this.state.loginForm));
        if (key === 'phone') {
            let val = e.target.value;
            val = val.replace(/[^\d]/g, '');
            if (val.length > 11) {
                val = val.substring(0, 11);
            }
            loginData[key] = val;
        } else {
            loginData[key] = e.target.value;
        }
        this.setState({
            loginForm: loginData,
        });
    }

    handlePhoneBlur(e) {
        let val = e.target.value;
        let err = '';
        // const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        const reg = /^[1][0-9]{10}$/;
        if (val && !(reg.test(val))) {
            err = '请输入正确的手机号码';
        } else {
            err = '';
        }
        this.setErr(err);
    }

    handleRegisterChange(e, key) {
        const registerData = JSON.parse(
            JSON.stringify(this.state.registerForm)
        );
        if (key === 'phone') {
            let val = e.target.value;
            val = val.replace(/[^\d]/g, '');
            if (val.length > 11) {
                val = val.substring(0, 11);
            }
            registerData[key] = val;
        } else {
            registerData[key] = e.target.value;
        }
        this.setState({
            registerForm: registerData,
        });
    }

    handleSelectChange(e, key) {
        const registerData = JSON.parse(
            JSON.stringify(this.state.registerForm)
        );
        if (key === 'province') {
            const { areaList } = this.state;
            const city = [];
            areaList.city.forEach(item => {
                if (item.parentId === e) {
                    city.push(item);
                }
            });
            registerData.city = '';
            registerData.distinct = '';
            this.setState({
                cityList: city
            });
        }
        if (key === 'city') {
            const { areaList } = this.state;
            const distinct = [];
            areaList.area.forEach(item => {
                if (item.parentId === e) {
                    distinct.push(item);
                }
            });
            registerData.distinct = '';
            this.setState({
                distinctList: distinct
            });
        }
        registerData[key] = e;
        this.setState({
            registerForm: registerData,
        });
    }

    validateCode() {
        // this.setState({
        //     agreement: data === 1 ? 2 : 1,
        // });
        if (this.state.loginModdelTab === 1) {
            this.setState({
                registIfSendMsg: true,
            });
            this.countLogin("registTimerCount");
        } else {
            this.setState({
                loginIfSendMsg: true,
            });
            this.countLogin("loginTimerCount");
        }
    }

    countLogin(a) {
        const { loginModdelTab } = this.state;
        let timerCount = this.state[a];
        let timer = setInterval(() => {
            if (loginModdelTab === 0) {
                this.setState(
                    {
                        loginTimerCount: --timerCount,
                    },
                    () => {
                        if (timerCount <= 0) {
                            clearInterval(timer);
                            this.setState({
                                loginIfSendMsg: false,
                                loginTimerCount: 60,
                            });
                        }
                    }
                );
            } else {
                this.setState(
                    {
                        registTimerCount: --timerCount,
                    },
                    () => {
                        if (timerCount <= 0) {
                            clearInterval(timer);
                            this.setState({
                                registIfSendMsg: false,
                                registTimerCount: 60,
                            });
                        }
                    }
                );
            }
        }, 1000);
    }

    doRegist() {
        const { registerForm } = this.state;
        // const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        const reg = /^[1][0-9]{10}$/;
        if (!reg.test(registerForm.phone)) {
            this.setErr("请输入正确的手机号");
            return;
        }
        if (!registerForm.msgCode) {
            this.setErr("请输入手机验证码");
            return;
        }
        if (!registerForm.name) {
            this.setErr("请输入姓名");
            return;
        }
        if (!registerForm.province) {
            this.setErr("请选择省/市/区");
            return;
        }
        if (!registerForm.city) {
            this.setErr("请选择省/市/区");
            return;
        }
        if (!registerForm.distinct) {
            this.setErr("请选择省/市/区");
            return;
        }
        if (!registerForm.company) {
            this.setErr("请输入公司");
            return;
        }
        this.setErr("");
        const params = {
            mobile: registerForm.phone,
            smsCode: registerForm.msgCode,
            name: registerForm.name,
            company: registerForm.company,
            provinceCode: registerForm.province,
            cityCode: registerForm.city,
            districtCode: registerForm.distinct,
        };
        loginApi
            .regist(params)
            .then(() => {
                this.setState({
                    registerForm: {
                        phone: "",
                        msgCode: "",
                        name: "",
                        company: "",
                        province: "",
                        city: "",
                        distinct: "",
                        err: "请准确输入信息，销售经理将尽快通过您填写的信息联系您",
                    },
                    loginModdelTab: 0,
                });
            })
            .catch((err) => {
                if (!err) this.setErr(err);
            });
    }

    goToDoc() {
        const url = window.location.href.split('#');
        window.open(url[0] + '/#/doc/7');
    }

    qrIconDom = () => {
        let icon = '';
        if (window.location.href.indexOf('//test') > -1 || window.location.href.indexOf('//localhost') > -1) {
            icon = 'https://img.dmallcdn.com/dshop/202103/6bdfc931-0566-4d5f-a7d4-5b6a037c5076';
        } else if (window.location.href.indexOf('//dev') > -1) {
            icon = 'https://img.dmallcdn.com/dshop/202103/2ab8c209-4a17-4cf5-9717-5e76f0a3e92e';
        } else {
            icon = 'https://img.dmallcdn.com/dshop/202103/a2877e02-a17d-416b-88a2-ea4def24ee0d';
        }
        return <img className="qr_register_icon" src={icon} />
    }

    render() {
        const {
            agreement,
            loginModdelTab,
            codeVisible,
            loginForm,
            registerForm,
            loginTimerCount,
            loginIfSendMsg,
            registTimerCount,
            registIfSendMsg,
            provinceList,
            cityList,
            distinctList
        } = this.state;
        const { isView } = this.props;
        return (
            <div
                className={
                    isView ? "loginModelContainer" : "loginModelContainer fixed"
                }
            >
                <div className="loginModelView" id="loginBox">
                    {!isView ? (
                        <img
                            className="loginModelClose"
                            onClick={this.removeModel}
                            src={Images.closePic}
                        />
                    ) : null}
                    <div className="loginModdelTab">
                        <div
                            className={
                                loginModdelTab === 0
                                    ? "loginPhone active"
                                    : "loginPhone"
                            }
                            onClick={() => {
                                this.setState({ loginModdelTab: 0 });
                            }}
                        >
                            手机验证码登录
                        </div>
                        {false &&
                            <div
                                className={
                                    loginModdelTab === 1
                                        ? "loginOpen active"
                                        : "loginOpen"
                                }
                                onClick={() => {
                                    this.setState({ loginModdelTab: 1 });
                                }}
                            >
                                申请开通服务
                            </div>
                        }
                    </div>
                    {loginModdelTab === 0 ? (
                        <div className="loginPhoneTab loginTab">
                            <div className="erropTip">
                                {loginForm.err && (
                                    <div className="errorTipView">
                                        <img src={Images.errorTipPic}></img>
                                        <span>{loginForm.err}</span>
                                    </div>
                                )}
                            </div>
                            <div className="input input1">
                                <div className="iconView">
                                    <img src={Images.phonePic} />
                                </div>
                                <input
                                    onBlur={(e) => this.handlePhoneBlur(e)}
                                    onChange={(e) =>
                                        this.handleLoginChange(e, "phone")
                                    }
                                    placeholder="请输入手机号"
                                    value={loginForm.phone}
                                />
                            </div>
                            <div className="input  input2">
                                <div className="iconView">
                                    <img src={Images.codePic} />
                                </div>
                                <input
                                    onChange={(e) =>
                                        this.handleLoginChange(e, "msgCode")
                                    }
                                    placeholder="请输入手机验证码"
                                    value={loginForm.msgCode}
                                />
                                <span
                                    className="codeView"
                                    onClick={() => this.showCodeModal()}
                                >
                                    {loginIfSendMsg
                                        ? loginTimerCount + "s"
                                        : "获取手机验证码"}
                                </span>
                            </div>
                            <div className="codeTipView">
                                {loginIfSendMsg && (
                                    <div className="codeTipBody">
                                        <img src={Images.codeSendPic} />
                                        <span>
                                            短信验证码已发送，可能会有延后请耐心等待
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                className="button"
                                onClick={() => this.doLogin()}
                            >
                                登录
                            </div>
                            <div className="loginTipView">
                                <input
                                    checked={agreement === 2 ? "checked" : ""}
                                    onChange={(e) => this.checkAgreement(e)}
                                    type="checkbox"
                                />
                                <div>
                                    我已同意<a onClick={() => this.goToDoc()}>《麦鲜达隐私协议》</a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="loginOpenTab loginTab">
                            <div className="erropTip">
                                {registerForm.err && (
                                    <div className="errorTipView">
                                        <img src={Images.errorTipPic}></img>
                                        <span>{registerForm.err}</span>
                                    </div>
                                )}
                            </div>
                            <div className="input input1 formInput">
                                <div className="label">手机号：</div>
                                <input
                                    onBlur={(e) => this.handlePhoneBlur(e)}
                                    onChange={(e) =>
                                        this.handleRegisterChange(e, "phone")
                                    }
                                    placeholder="请输入手机号"
                                    value={registerForm.phone}
                                />
                            </div>
                            <div className="input input2 formInput">
                                <div className="label">验证码：</div>
                                <input
                                    onChange={(e) =>
                                        this.handleRegisterChange(e, "msgCode")
                                    }
                                    placeholder="请输入手机验证码"
                                    value={registerForm.msgCode}
                                />
                                <span
                                    className="codeView"
                                    onClick={() => this.showCodeModal()}
                                >
                                    {registIfSendMsg
                                        ? registTimerCount + "s"
                                        : "获取手机验证码"}
                                </span>
                            </div>
                            <div className="codeTipView">
                                {registIfSendMsg && (
                                    <div className="codeTipBody">
                                        <img src={Images.codeSendPic} />
                                        <span>
                                            短信验证码已发送，可能会有延后请耐心等待
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                className="input formInput"
                                style={{ marginTop: 0 }}
                            >
                                <div className="label">姓名：</div>
                                <input
                                    onChange={(e) =>
                                        this.handleRegisterChange(e, "name")
                                    }
                                    placeholder="请输入姓名"
                                    value={registerForm.name}
                                />
                            </div>
                            <div
                                className="input formInput"
                            >
                                <div className="label">地区：</div>
                                <div className="select-box">
                                    <Select className="province-select" dropdownMatchSelectWidth={false} onChange={(e) => this.handleSelectChange(e, 'province')} value={registerForm.province}>
                                        {
                                            provinceList.map(item => (
                                                <Option key={item.id} value={item.id}>{item.areaName}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Select className="city-select" dropdownMatchSelectWidth={false} onChange={(e) => this.handleSelectChange(e, 'city')} value={registerForm.city}>
                                        {
                                            cityList.map(item => (
                                                <Option key={item.id} value={item.id}>{item.areaName}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Select className="distinct-select" dropdownMatchSelectWidth={false} onChange={(e) => this.handleSelectChange(e, 'distinct')} value={registerForm.distinct}>
                                        {
                                            distinctList.map(item => (
                                                <Option key={item.id} value={item.id}>{item.areaName}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="input  formInput">
                                <div className="label">公司：</div>
                                <input
                                    onChange={(e) =>
                                        this.handleRegisterChange(e, "company")
                                    }
                                    placeholder="请输入公司"
                                    value={registerForm.company}
                                />
                            </div>
                            <div
                                className="button"
                                onClick={() => this.doRegist()}
                            >
                                提交
                            </div>
                        </div>
                    )}
                </div>
                {/* <div className="userTrack">
                    <Tooltip color="#ffffff" title={this.qrIconDom()}>
                        <a>非配送客户 账号申请</a>
                    </Tooltip>
                </div> */}
                <CodeModal
                    callBack={(data) => this.validateCode(data)}
                    hideCodeModal={() => this.hideCodeModal()}
                    id="loginBox"
                    needSupportFsd={loginModdelTab}
                    phone={this.getCurrentPhone()}
                    visible={codeVisible}
                />
            </div>
        );
    }
}
export default withRouter(LoginModel);
