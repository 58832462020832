const initState = {
    addrList: [],
    currentAddr: {},
}

const addrReducer = (state = initState, action) => {
    switch (action.type) {
        case 'setCurrentAddr':
            return { ...state, currentAddr: action.payload };
        case 'setAddrList':
            return { ...state, addrList: action.payload };
        default:
            return state || {};
    }
}

export default addrReducer;