import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import "@babel/polyfill";
import "@/common/css/animate.css";
import "@/common/css/index.css";
import Router from "./routers/index";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";

const store = createStore(rootReducer, applyMiddleware(thunk));

// var tracker = window.DmallTracker;

// console.log("dmall tracker", tracker.instance);

// tracker.instance({
//     project: "fsd站点",
// });

ReactDOM.render(
    <Provider store={store}>
        <Router />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
