import React, { useState, useEffect, useRef } from "react";
import { Input, AutoComplete, Spin } from "antd";
import "./goodSearch.scss";
import { goodsApi } from "../../api/api";
import { useDispatch } from "react-redux";
import { fetchStatistic } from "@/redux/actions";

function GoodSearch(props) {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [showContent, setShowContent] = useState(false);
    const autoRef = useRef(null);
    const dispatch = useDispatch();

    function renderOptions() {
        setOptions([]);
        let optios = data.map((item, index) => {
            const value = item.goodsAlias || "";
            const label = (
                <div className="searchItem" key={index}>
                    <div className="itemDescView">
                        <div className="descTitle">
                            【{item.rfId}】{item.goodsAlias}
                        </div>
                        <div className="descInfo">
                            {item.weight === 1 ? '' : item.packageNum}
                            {item.chine}{" "}
                        </div>
                    </div>
                    <div className="itemPriceView">¥{item.calPrice}</div>
                </div>
            );
            return {
                value: value,
                label: label,
            };
        });
        setOptions(optios);
    }

    function fetchGoods() {
        setLoading(true);
        goodsApi
            .fetchGoodsByWordsOnCart({
                page: 1,
                pageSize: 100,
                keyword: keyword,
                filterSellOut: true,
            })
            .then((res) => {
                setData(res);
                setLoading(false);
            });
    }

    function handleSearch(value) {
        setKeyword(value);
    }

    function onSelect(value, option) {
        console.log(value, option);
        setKeyword(value);
        let item = data.find((item) => item.goodsAlias === value);

        if (props.noSearchButton) {
            props.changeKeyWord(value);
        } else {
            props.addGoodToCart(item.skuId + "", 1).then(() => {
                dispatch(fetchStatistic());
            });
        }
        setShowContent(false);
    }

    useEffect(() => {
        renderOptions();
    }, [data]);

    return (
        <div
            className={
                props.noSearchButton
                    ? "GoodNoSearchContainer"
                    : "GoodSearchContainer"
            }
        >
            <AutoComplete
                dropdownClassName="GoodSearchSelectDown"
                dropdownMatchSelectWidth={342}
                notFoundContent={
                    loading ? <Spin size="small" /> : <span>暂无数据</span>
                }
                onBlur={() => setShowContent(false)}
                // onFocus={() => setShowContent(true)}
                onSearch={handleSearch}
                onSelect={onSelect}
                open={showContent}
                options={options}
                placeholder="商品编码或名称"
                ref={autoRef}
                value={keyword}
            >
                {props.noSearchButton ? null : (
                    <Input.Search
                        allowClear
                        enterButton="搜索"
                        loading={loading}
                        onPressEnter={() => {
                            fetchGoods();
                            console.log("autoRef", autoRef.current);
                            autoRef.current.focus();
                            setShowContent(true);
                        }}
                        onSearch={() => {
                            fetchGoods();
                            setShowContent(true);
                        }}
                    />
                )}
            </AutoComplete>
        </div>
    );
}
export default GoodSearch;
