import React from 'react';
import { Spin } from 'antd';
import './index.scss';

const LoadingBox = (props) => {
    let { loading, tip, children } = props;
    return (
        <div className="loading-box">
            <Spin spinning={loading} tip={tip || ''} >
                {children}
            </Spin>
        </div>
    );
}
export default LoadingBox;