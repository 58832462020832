const initState = {
    msgCount: 0,
    typeCount: {}
}

const msgReducer = (state = initState, action) => {
    switch (action.type) {
        case 'setMsgCount':
            // eslint-disable-next-line no-case-declarations
            const newState = action.payload;
            return { ...state, ...newState };
        default:
            return state || {};
    }
}

export default msgReducer;