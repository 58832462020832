import React from "react";
import { Images } from "../../../assets/index";
import "./statusOrder.scss";
import { useHistory } from "react-router-dom";
import { Image } from "antd";

function StatusOrder(props) {
    const history = useHistory();

    function jumpGoodsDetail() {
        history.push("/goodsDetail/" + props.orderItem.goodsRfid);
    }

    return (
        <div className="statusOrderContent">
            <div className="headImage">
                <Image
                    fallback={Images.defaultGoodsPic}
                    onClick={jumpGoodsDetail}
                    preview={false}
                    src={
                        props.orderItem.pictureUrl + "?imageView2/2/w/182" ||
                        Images.defaultGoodsPic
                    }
                />
            </div>
            <a className="orderTitle" onClick={jumpGoodsDetail}>
                【{props.orderItem.goodsRfid}】{props.orderItem.goodsName}
            </a>
            <div className="orderPriceView">
                <div className="orderText">
                    ￥{props.orderItem.priceShow.toFixed(2)}
                </div>
            </div>
            <div className="orderItemView orderCountView">
                <div className="orderText">
                    {props.orderItem.weight == 1
                        ? props.orderItem.weightQty
                        : props.orderItem.numberQty}
                </div>
            </div>
            <div className="orderItemView orderAmountView">
                <div className="orderText">
                    ￥{props.orderItem.totalPriceShow.toFixed(2)}
                </div>
            </div>
        </div>
    );
}

export default StatusOrder;
