const initState = {
    userInfo: {}
}

const userInfoReducer = (state = initState, action) => {
    switch (action.type) {
        case 'setUserInfo':
            return { ...state, userInfo: action.payload };
        default:
            return state || {};
    }
}

export default userInfoReducer;