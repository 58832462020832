import React from "react";
import ReactDOM from "react-dom";
import ReEntryModal from "./reEntryModal.jsx";

let notification;
function createModel(props) {
    const div = document.createElement("div");
    document.body.appendChild(div);
    ReactDOM.render(React.createElement(ReEntryModal, props), div);
    return {
        destroy() {
            ReactDOM.unmountComponentAtNode(div);
            document.body.removeChild(div);
            notification = null;
        },
    };
}
const notice = (props) => {
    notification = createModel(props);
    return notification;
};
export default {
    show(props) {
        return notice(props);
    },
    getFlag() {
        if (notification) {
            return true;
        } else {
            return false;
        }
    },
    hide() {
        if (notification) {
            return notification.destroy();
        }
    },
};
